import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Form, Input, Modal, Switch, TimePicker } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs, { type Dayjs } from 'dayjs';
import Timezone from 'dayjs/plugin/timezone';
import Utc from 'dayjs/plugin/utc';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFetcher, useLoaderData } from 'react-router-dom';

import { LoaderData } from '@/services/types/loader-data';
import { OrganizationSettings } from '@/types/organization-settings';

import { loader } from './loader';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(Utc);
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(Timezone);

interface SettingsForm {
  work_hours?: [Dayjs, Dayjs];
  off_hours_response: string | null;
  automatic_introduction: boolean;
  receipt_for_all: boolean;
}

const OrganizationManagement: FC = () => {
  const settings = useLoaderData() as LoaderData<typeof loader>;
  const [form] = useForm<SettingsForm>();
  const { submit, state } = useFetcher<Response | OrganizationSettings>();
  const [modal, contextHolder] = Modal.useModal();
  const isDisabled = useMemo(() => state === 'submitting' || state === 'loading', [state]);
  const [submittable, setSubmittable] = useState(false);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(form.isFieldsTouched());
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [form, values]);

  useEffect(() => {
    form.setFieldsValue({
      work_hours:
        settings?.work_start && settings?.work_end
          ? [dayjs(settings?.work_start, 'HH:mm:ssZ'), dayjs(settings?.work_end, 'HH:mm:ssZ')]
          : undefined,
      off_hours_response: settings?.off_hours_response || null,
      automatic_introduction: settings?.automatic_introduction ?? true,
      receipt_for_all: settings?.receipt_for_all ?? false,
    });
  }, [settings, form]);

  const showClearConfirm = useCallback(() => {
    modal.confirm({
      title: 'Clear work hours settings',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to clear work hours settings?',
      onOk() {
        const data = {
          work_start: null,
          work_end: null,
          off_hours_response: null,
          automatic_introduction: settings.automatic_introduction ?? true,
        };
        submit(
          {
            intent: 'update-organization',
            ...data,
          },
          {
            method: 'patch',
            encType: 'application/json',
          },
        );
      },
    });
  }, [modal, settings.automatic_introduction, submit]);

  const onFinish = useCallback(
    (values: SettingsForm) => {
      if (settings === undefined) {
        return;
      }

      const initialSettings = settings;
      const changedValues = Object.fromEntries(
        Object.entries(
          Object.entries(values).reduce(
            (acc, [key, value]) =>
              // TODO: correct comparison considering timezone differences
              key === 'work_hours'
                ? {
                    ...acc,
                    work_start:
                      value === null || value === undefined
                        ? undefined
                        : (value[0] as dayjs.Dayjs).format('HH:mmZ').substring(0, 8),
                    work_end:
                      value === null || value === undefined
                        ? undefined
                        : (value[1] as dayjs.Dayjs).format('HH:mmZ').substring(0, 8),
                  }
                : { ...acc, [key]: value },
            {} as OrganizationSettings,
          ),
        ).filter(([key, value]) => initialSettings[key as keyof OrganizationSettings] !== value && value !== undefined),
      );

      try {
        if (Object.keys(changedValues).length > 0) {
          submit(
            {
              intent: 'update-organization',
              ...changedValues,
            },
            {
              method: 'patch',
              encType: 'application/json',
            },
          );
        }
      } catch (error) {
        console.warn(`Error updating the work hours settings:`, error);
      }
    },
    [settings, submit],
  );

  return (
    <Form form={form} {...layout} style={{ maxWidth: 600 }} onFinish={onFinish}>
      <Form.Item
        label="Work hours"
        name="work_hours"
        help={'Work hours are in your local time (' + dayjs.tz.guess() + ')'}
        hasFeedback
        validateStatus="warning"
      >
        <TimePicker.RangePicker format="HH:mm" order={false} disabled={isDisabled} allowEmpty={true} />
      </Form.Item>
      <Form.Item
        label="Off-hours response"
        name="off_hours_response"
        rules={[
          (formInstance) => ({
            message: 'The response text is required when work hours are set.',
            validator: (rule, value) => {
              const work_hours = formInstance.getFieldValue('work_hours');
              if (!work_hours) {
                return Promise.resolve();
              }

              if (value === undefined || value === null || (typeof value === rule.type && value.length === 0)) {
                return Promise.reject(new Error());
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input.TextArea placeholder="spome text" disabled={isDisabled} rows={6} />
      </Form.Item>
      <Form.Item label="Automatic inroductions" name="automatic_introduction" valuePropName="checked">
        <Switch disabled={isDisabled} />
      </Form.Item>
      <Form.Item label="Upon reply mark as Read for all" name="receipt_for_all" valuePropName="checked">
        <Switch disabled={isDisabled} />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" loading={isDisabled} disabled={isDisabled || !submittable}>
          Submit
        </Button>
        <Button danger onClick={showClearConfirm} style={{ marginLeft: '10px' }} disabled={isDisabled}>
          Clear
        </Button>
      </Form.Item>
      {contextHolder}
    </Form>
  );
};

export default OrganizationManagement;
