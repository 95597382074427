import Composer from '@pages/ChatFeed/RightColumn/Composer';
import { EDITABLE_INPUT_CSS_SELECTOR, EDITABLE_INPUT_ID } from '@pages/ChatFeed/RightColumn/index';
import MessageList from '@pages/ChatFeed/RightColumn/MessageList';
import ScrollDownButton from '@pages/ChatFeed/RightColumn/ScrollDownButton';
import type { FC } from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import { ConversationHandler } from '@/context/socket.context';
import usePreviousDuringAnimation from '@/hooks/use-previous-during-animation';
import useSocket from '@/hooks/use-socket';
import useSyncEffect from '@/hooks/use-sync-effect';
import { LoaderData } from '@/services/types/loader-data';
import { UserConversation } from '@/types/conversation';
import buildClassName from '@/utils/build-class-name';

import type { loader } from './loader';
import styles from './RightColumn.module.scss';

interface StateProps {
  conversation: UserConversation;
}

const NonMemoRightColumn: FC<StateProps> = ({ conversation }) => {
  const rightColumnRef = useRef<HTMLDivElement>(null);
  const [isFabShown, setIsFabShown] = useState<boolean>();
  const [isNotchShown, setIsNotchShown] = useState<boolean>();

  const renderingIsFabShown = usePreviousDuringAnimation(isFabShown, 0);

  const canPost = conversation.is_authorized;
  const messagingDisabled = !canPost;

  useSyncEffect(() => {
    setIsNotchShown(undefined);
  }, [conversation.id]);

  const footerClassName = buildClassName(
    styles.footer,
    !canPost && styles.noComposer,
    canPost && isNotchShown && 'with-notch',
  );

  return (
    <div ref={rightColumnRef} className={styles.columnWrapper} id="RightColumn">
      {conversation.id && (
        <>
          <div className={buildClassName('messages-layout', styles.messagesLayout)}>
            <MessageList
              key={`${conversation.id}-conversation`}
              conversation={conversation}
              canPost={canPost}
              onFabToggle={setIsFabShown}
              onNotchToggle={setIsNotchShown}
            />
            <div className={footerClassName}>
              {canPost && (
                <Composer
                  editableInputId={EDITABLE_INPUT_ID}
                  editableInputCssSelector={EDITABLE_INPUT_CSS_SELECTOR}
                  conversationId={conversation.id}
                  conversationLanguageCode={conversation.language_code}
                  containerRef={rightColumnRef}
                />
              )}
              {messagingDisabled && <div className={styles.messagingDisabled}>DISABLED</div>}
            </div>
            <ScrollDownButton isShown={renderingIsFabShown || false} />
          </div>
        </>
      )}
    </div>
  );
};

const RightColumn = memo(function RightColumn() {
  const socket = useSocket();
  const fetchedConversation = useLoaderData() as LoaderData<typeof loader>;
  const [conversation, setConversation] = useState(fetchedConversation);

  useEffect(() => {
    setConversation(fetchedConversation);
  }, [fetchedConversation]);

  useEffect(() => {
    const onConversation: ConversationHandler = (updatedConversation) => {
      setConversation((conversation) => ({ ...conversation, ...updatedConversation }));
    };

    socket.on(`conversation/${Number(conversation.id)}`, onConversation);

    return () => {
      socket.off(`conversation/${Number(conversation.id)}`, onConversation);
    };
  }, [conversation.id, socket]);

  return <NonMemoRightColumn conversation={conversation} />;
});
export default RightColumn;
