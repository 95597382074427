import { EDITABLE_INPUT_ID, EDITABLE_INPUT_MODAL_ID } from '@pages/ChatFeed/RightColumn';
import getFilesFromDataTransferItems from '@pages/ChatFeed/RightColumn/Composer/helpers/get-files-from-data-transfer-items.ts';
import { useEffect } from 'react';

import buildAttachment from '@/helpers/build-attachment.ts';
import { Attachment } from '@/types/attachment.ts';

const MAX_MESSAGE_LENGTH = 4096;

const STYLE_TAG_REGEX = /<style>(.*?)<\/style>/gs;

function preparePastedHtml(html: string) {
  const fragment = document.createElement('div');
  fragment.innerHTML = html.replace(/\u00a0/g, ' ').replace(STYLE_TAG_REGEX, ''); // Strip &nbsp and styles

  return fragment.innerHTML.trimEnd();
}

const useClipboardPaste = (
  insertTextAndUpdateCursor: (text: string, inputId?: string) => void,
  setAttachments: (attachments: Attachment[]) => void,
  setText: (value: string) => void,
) => {
  useEffect(() => {
    async function handlePaste(e: ClipboardEvent) {
      if (e.clipboardData === null) {
        return;
      }

      const input = document.activeElement;
      if (input && ![EDITABLE_INPUT_ID, EDITABLE_INPUT_MODAL_ID].includes(input.id)) {
        return;
      }
      const pastedText = e.clipboardData.getData('text').substring(0, MAX_MESSAGE_LENGTH);

      const { items } = e.clipboardData;
      let files: File[] | undefined = [];

      e.preventDefault();
      if (items.length > 0) {
        files = await getFilesFromDataTransferItems(items);
      }

      if (!files?.length && !pastedText) {
        return;
      }

      const textToPaste = preparePastedHtml(pastedText);

      const hasText = !!textToPaste;
      const shouldSetAttachments = files?.length !== undefined && files.length > 0;

      const attachments = files ? await Promise.all(files.map((file) => buildAttachment(file.name, file))) : [];

      if (shouldSetAttachments) {
        setAttachments(attachments);
      }

      if (hasText) {
        if (shouldSetAttachments) {
          setText(textToPaste);
        } else {
          insertTextAndUpdateCursor(textToPaste, input?.id);
        }
      }
    }

    document.addEventListener('paste', handlePaste, false);

    return () => {
      document.removeEventListener('paste', handlePaste, false);
    };
  }, [insertTextAndUpdateCursor, setAttachments, setText]);
};

export default useClipboardPaste;
