export enum FocusDirection {
  Up,
  Down,
  Static,
}

export interface FocusedMessage {
  conversationId?: number;
  messageId?: number;
  direction?: FocusDirection;
}
