import { useContext } from 'react';

import { SocketContext } from '@/context/socket.context';

function useSocket() {
  const socketContext = useContext(SocketContext);
  if (socketContext === undefined) {
    throw new Error('No context found for SocketContext');
  }

  return socketContext();
}

export default useSocket;
