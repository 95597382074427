import { useRef } from 'react';

import useForceUpdate from '@/hooks/use-force-update';
import usePrevious from '@/hooks/use-previous';
import useSyncEffect from '@/hooks/use-sync-effect';

export default function usePreviousDuringAnimation<T>(current: T, duration?: number) {
  const prev = usePrevious(current, true);
  const timeoutRef = useRef<number>();
  const forceUpdate = useForceUpdate();
  const isCurrentPresent = current !== undefined && current !== null;
  const isPrevPresent = prev !== undefined && prev !== null;

  if (isCurrentPresent && timeoutRef.current) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
  }

  useSyncEffect(() => {
    // When `current` becomes empty
    if (duration && !isCurrentPresent && isPrevPresent && !timeoutRef.current) {
      timeoutRef.current = window.setTimeout(() => {
        timeoutRef.current = undefined;
        forceUpdate();
      }, duration);
    }
  }, [duration, forceUpdate, isCurrentPresent, isPrevPresent]);

  return !timeoutRef.current || !duration || isCurrentPresent ? current : prev;
}
