import type { FC } from 'react';

interface OwnProps {
  message: string | null;
}

const MessageText: FC<OwnProps> = ({ message }) => {
  return message;
};

export default MessageText;
