import { useContext } from 'react';

import { ColorSchemeContext } from '@/context/color-scheme.context.tsx';

function usePrefersColorScheme() {
  const colorSchemeContext = useContext(ColorSchemeContext);
  if (colorSchemeContext === undefined) {
    throw new Error('No context defined for ColorSchemeContext');
  }

  return colorSchemeContext;
}

export default usePrefersColorScheme;
