import { Button, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { type FC, useCallback, useEffect } from 'react';
import { useFetcher } from 'react-router-dom';

import { languages } from '@/types/language';
import type { TelegramGroup } from '@/types/telegram-group';

const isErrorResponse = (response: Response | TelegramGroup): response is Response => {
  return 'ok' in response && !response.ok;
};

interface Values {
  id: string;
  name: string;
  language_code: string;
}

interface OwnProps {
  open: boolean;
  onCreate: (group: TelegramGroup) => void;
  onCancel: () => void;
}

const CreateConversationForm: FC<OwnProps> = ({ open, onCreate, onCancel }) => {
  const { submit, state, data: response } = useFetcher<Response | TelegramGroup>();
  const [form] = useForm<Values>();

  useEffect(() => {
    if (response === undefined) {
      return;
    }

    if (isErrorResponse(response)) {
      form.setFields([
        {
          name: 'id',
          errors: [response.statusText],
        },
      ]);
      return;
    }

    form.resetFields();
    onCreate(response);
  }, [form, onCreate, response]);

  const onSubmit = useCallback(async () => {
    const values = await form.validateFields();
    submit(
      {
        intent: 'authorize',
        ...values,
      },
      {
        method: 'post',
        encType: 'application/json',
      },
    );
  }, [form, submit]);

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <Modal
      open={open}
      title="Create a new conversation"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={state === 'submitting'} onClick={form.submit}>
          Create
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        disabled={state === 'submitting'}
        initialValues={{ language_code: 'en' }}
      >
        <Form.Item
          name="id"
          label="Telegram group ID"
          rules={[
            {
              required: true,
              message: 'Please input the id!',
            },
            {
              type: 'string',
              pattern: /^-[1-9][0-9]*$/,
              message: 'ID of the group must be a negative number',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Conversation name"
          rules={[
            {
              required: true,
              message: 'Please input the name!',
            },
            {
              max: 96,
              message: 'Name can not exceed 96 characters.',
            },
          ]}
        >
          <Input count={{ show: true, max: 96 }} />
        </Form.Item>
        <Form.Item
          name="language_code"
          label="Default language"
          rules={[
            {
              required: true,
              message: 'Please select the default language for the conversation',
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={Object.entries(languages).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateConversationForm;
