import { SearchOutlined } from '@ant-design/icons';
import { Input, InputRef } from 'antd';
import { type ChangeEvent, type FC, type RefObject, useRef } from 'react';

import buildClassName from '@/utils/build-class-name.ts';

import styles from './SearchInput.module.scss';

interface OwnProps {
  ref?: RefObject<InputRef>;
  className?: string;
  inputId?: string;
  value?: string;
  onChange: (value: string) => void;
}

const SearchInput: FC<OwnProps> = ({ ref, className, inputId, value, onChange }) => {
  let inputRef = useRef<InputRef>(null);
  if (ref) {
    inputRef = ref;
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { currentTarget } = event;
    onChange(currentTarget.value);
  }

  return (
    <div className={buildClassName(styles.searchInput, className)}>
      <Input
        ref={inputRef}
        id={inputId}
        placeholder="Search"
        prefix={<SearchOutlined />}
        onChange={handleChange}
        value={value}
        allowClear
      />
    </div>
  );
};

export default SearchInput;
