import { type ActionFunction } from 'react-router-dom';

import type { AuthContextType } from '@/context/auth.context';
import { actionRequest } from '@/services/action-request.ts';
import { MessageTemplate, MessageTemplateTranslation } from '@/types/message-template.ts';

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const data = await request.json();
    const intent = data.intent;

    switch (intent) {
      case 'add-template':
        return await actionRequest<MessageTemplate>(auth, request, 'manage/message-template', data);
      case 'add-template-translation': {
        const templateId = data.template_id;
        return await actionRequest<MessageTemplateTranslation>(
          auth,
          request,
          `manage/message-template/${templateId}/translation`,
          data,
        );
      }
      case 'update-template': {
        const templateId = data.id;
        delete data.id;
        return await actionRequest<MessageTemplate>(auth, request, `manage/message-template/${templateId}`, data);
      }
      case 'update-translation': {
        const templateId = data.template_id;
        const languageCode = data.language_code_previous;
        delete data.template_id;
        delete data.language_code_previous;
        return await actionRequest<MessageTemplate>(
          auth,
          request,
          `manage/message-template/${templateId}/translation/${languageCode}`,
          data,
        );
      }
      case 'delete-template': {
        const templateId = data.id;
        return await actionRequest<Response>(auth, request, `manage/message-template/${templateId}`, data);
      }
      case 'delete-translation': {
        const templateId = data.template_id;
        const languageCode = data.language_code;
        return await actionRequest<Response>(
          auth,
          request,
          `manage/message-template/${templateId}/translation/${languageCode}`,
          data,
        );
      }
      default:
        throw new Error('Undefined intent for the action');
    }
  }) satisfies ActionFunction;
