import { PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import AttachmentModal from '@pages/ChatFeed/RightColumn/Composer/AttachmentModal';
import ComposerEmbeddedMessage from '@pages/ChatFeed/RightColumn/Composer/ComposerEmbeddedMessage';
import { Button } from 'antd';
import { type FC, memo, type RefObject, useCallback, useEffect, useRef, useState } from 'react';

import buildAttachment from '@/helpers/build-attachment.ts';
import useAuth from '@/hooks/use-auth';
import useClipboardPaste from '@/hooks/use-clipboard-paste';
import useLastCallback from '@/hooks/use-last-callback';
import useSocket from '@/hooks/use-socket';
import { useStateRef } from '@/hooks/use-state-ref';
import useSyncEffect from '@/hooks/use-sync-effect.ts';
import { useSelector } from '@/store';
import { removeInputMessageReplyInfo } from '@/store/reducers/messages.ts';
import { selectInputMessageReplyInfo } from '@/store/selectors/messages.ts';
import { type Attachment } from '@/types/attachment.ts';
import { Message } from '@/types/message.ts';
import { type MessageTemplate } from '@/types/message-template.ts';
import { sendAttachments } from '@/utils/api/send-attachments.ts';
import { sendMessage } from '@/utils/api/send-message';
import { validateFiles } from '@/utils/file.ts';
import focusEditableElement from '@/utils/focus-editable-element.ts';
import parseMessageInput from '@/utils/parse-message-input';
import { insertHtmlInSelection } from '@/utils/selection';
import { openSystemFilesDialog } from '@/utils/ui/system-files-dialog.ts';

import styles from './Composer.module.scss';
import { isSelectionInsideInput } from './helpers/selection';
import MessageInput from './MessageInput';
import MessageTemplatesPopover from './MessageTemplatesPopover';
import { type PopoverTemplate } from './MessageTemplatesPopover/MessageTemplatesPopover.tsx';

interface OwnProps {
  conversationId: number;
  conversationLanguageCode: string;
  editableInputId: string;
  editableInputCssSelector: string;
  containerRef: RefObject<HTMLDivElement>;
}

interface StateProps {
  messageTemplates: PopoverTemplate[];
  replyToMessage?: Message;
}

const NonMemoComposer: FC<Omit<OwnProps, 'conversationLanguageCode'> & StateProps> = ({
  conversationId,
  editableInputId,
  editableInputCssSelector,
  containerRef,
  messageTemplates,
  replyToMessage,
}) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const [html, setHtml] = useState('');
  const auth = useAuth();
  const socket = useSocket();
  const [slashCommand, setSlashCommand] = useState<string | null>(null);
  const hasMessageTemplates = messageTemplates && messageTemplates.length > 0;
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const hasAttachments = Boolean(attachments.length);

  useEffect(() => {
    if (html.startsWith('/')) {
      setSlashCommand(html.slice(1));
    } else {
      setSlashCommand(null);
    }
  }, [html]);

  useSyncEffect(
    ([prevReplyToMessage]) => {
      if (!prevReplyToMessage && replyToMessage) {
        focusEditableElement(inputRef.current!);
      }
    },
    [replyToMessage],
  );

  const insertHtmlAndUpdateCursor = useLastCallback((newHtml: string, inInputId: string = editableInputId) => {
    const selection = window.getSelection()!;
    let messageInput: HTMLDivElement;
    if (inInputId === editableInputId) {
      messageInput = document.querySelector<HTMLDivElement>(editableInputCssSelector)!;
    } else {
      messageInput = document.getElementById(editableInputId) as HTMLDivElement;
    }

    if (selection.rangeCount) {
      const selectionRange = selection.getRangeAt(0);
      if (isSelectionInsideInput(selectionRange, editableInputId)) {
        insertHtmlInSelection(newHtml);
        messageInput.dispatchEvent(new Event('input', { bubbles: true }));
        return;
      }
    }

    setHtml(`${html}${newHtml}`);
  });

  const resetComposer = useLastCallback(() => {
    setHtml('');
    setTimeout(setAttachments, 0, []);
    removeInputMessageReplyInfo();
  });

  const resetComposerRef = useStateRef(resetComposer);
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      resetComposerRef.current();
    };
  }, [conversationId, resetComposerRef]);

  const handleSend = useLastCallback(async () => {
    const currentUserId = auth.getUserId();
    const authorName = auth.getAuthorName();
    const content = parseMessageInput(html.replace(/<br\s*\/?>/gi, '\n').trim());
    if ((content.length === 0 && attachments.length === 0) || currentUserId === undefined) {
      return;
    }

    if (attachments.length > 0) {
      // noinspection ES6MissingAwait
      sendAttachments(
        auth,
        socket,
        conversationId,
        currentUserId,
        attachments,
        content,
        auth.getAuthorName(),
        replyToMessage?.id,
      );
    } else {
      // noinspection ES6MissingAwait
      sendMessage(auth, socket, conversationId, content, currentUserId, authorName, replyToMessage?.id);
    }
    resetComposer();
    // focus?
  });

  const onSend = handleSend;
  const mainButtonHandler = handleSend;

  const mainButtonDisabled = html === '' && attachments.length === 0;

  const handleClearAttachments = useLastCallback(() => {
    setAttachments([]);
  });

  const handleSetAttachments = useLastCallback((newAttachments: Attachment[]) => {
    if (!newAttachments.length) {
      handleClearAttachments();
    }

    setAttachments(newAttachments);
  });

  useClipboardPaste(insertHtmlAndUpdateCursor, handleSetAttachments, setHtml);

  const handleFileSelect = useLastCallback((e: Event) => {
    const { files } = e.target as HTMLInputElement;
    const validatedFiles = validateFiles(files);

    if (!validatedFiles?.length) {
      return;
    }

    const attachments = validatedFiles.map((file) => buildAttachment(file.name, file));
    handleSetAttachments(attachments);
  });

  const handleCaptionUpdate = useLastCallback((caption: string) => {
    setHtml(caption);
  });

  return (
    <div className={styles.composer}>
      <AttachmentModal
        conversationId={conversationId}
        attachments={attachments}
        getHtml={() => html}
        onSend={handleSend}
        onCaptionUpdate={handleCaptionUpdate}
        onClear={handleClearAttachments}
      />
      <div className={styles.composerWrapper}>
        {replyToMessage && (
          <ComposerEmbeddedMessage onClear={() => removeInputMessageReplyInfo()} conversationId={conversationId} />
        )}
        <div className={styles.composerBottomWrapper}>
          <div className={styles.messageInputWrapper}>
            {hasMessageTemplates && (
              <MessageTemplatesPopover
                command={slashCommand}
                templates={messageTemplates}
                className={styles.templatesButton}
                popupContainerRef={containerRef}
                onTemplateSelected={(template) => {
                  setHtml(template.replace(/\r?\n/g, '<br />'));
                  focusEditableElement(inputRef.current!);
                }}
                onClose={() => {
                  if (html.startsWith('/')) {
                    setHtml('');
                  }
                }}
              />
            )}
            <MessageInput
              ref={inputRef}
              id="message-input-text"
              isActive={!hasAttachments}
              editableInputId={editableInputId}
              conversationId={conversationId}
              getHtml={() => html}
              placeholder="Type a message"
              canAutoFocus={true}
              onUpdate={setHtml}
              onSend={onSend}
              withMessageTemplatesButton={hasMessageTemplates}
            />
            <Button
              className={styles.attachButton}
              type="link"
              icon={<PaperClipOutlined style={{ fontSize: '1.2rem' }} />}
              onClick={() => openSystemFilesDialog('*', handleFileSelect)}
            />
          </div>
          <Button
            type="primary"
            size="large"
            className={styles.mainButton}
            disabled={mainButtonDisabled}
            onClick={mainButtonHandler}
            icon={<SendOutlined />}
          />
        </div>
      </div>
    </div>
  );
};

const Composer = memo<OwnProps>(function Composer(props: OwnProps) {
  const templateSelector = useCallback(
    (templates?: MessageTemplate[]) =>
      templates?.map((template) => ({
        id: template.id,
        command: template.slug,
        template:
          template.translations.find((translation) => translation.language_code === props.conversationLanguageCode)
            ?.template || template.translations.find((translation) => translation.language_code === 'en')!.template,
      })) || [],
    [props.conversationLanguageCode],
  );
  // TODO: applying selector function directly to useSelector has no effect, investigate
  const templates = useSelector<MessageTemplate[], MessageTemplate[]>('message-templates', (state) => state);
  const messageTemplates = templateSelector(templates);

  const inputMessageReplyInfo = selectInputMessageReplyInfo();

  return (
    <NonMemoComposer
      conversationId={props.conversationId}
      editableInputId={props.editableInputId}
      editableInputCssSelector={props.editableInputCssSelector}
      containerRef={props.containerRef}
      messageTemplates={messageTemplates}
      replyToMessage={inputMessageReplyInfo}
    />
  );
});

export default Composer;
