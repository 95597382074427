import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';

import useLocalStorage from '@/hooks/use-local-storage.ts';
import useMediaQuery from '@/hooks/use-media-query.ts';

export type ColorSchemePreference = 'light' | 'dark' | 'no-preference';

const COLOR_SCHEME_QUERY_DARK = '(prefers-color-scheme: dark)';
const COLOR_SCHEME_QUERY_LIGHT = '(prefers-color-scheme: light)';

interface ColorContext {
  preferredColorScheme: ColorSchemePreference;
  setPreferredColorScheme: (scheme: 'light' | 'dark') => void;
  isUserSet: boolean;
  resetPreference: () => void;
}

export const ColorSchemeContext = createContext<ColorContext | undefined>(undefined);

export const ColorSchemeProvider = ({ children }: { children: ReactNode }) => {
  const [preferredColorScheme, setPreferredColorScheme] = useState<ColorSchemePreference>(() => {
    // if window is undefined (SSR), return 'no-preference'
    if (typeof window === 'undefined') {
      return 'no-preference';
    }

    const isDark = window.matchMedia(COLOR_SCHEME_QUERY_DARK).matches;
    const isLight = window.matchMedia(COLOR_SCHEME_QUERY_LIGHT).matches;

    return isDark ? 'dark' : isLight ? 'light' : 'no-preference';
  });
  const [userColorScheme, setUserColorScheme, unsetUserColorScheme] = useLocalStorage<ColorSchemePreference>(
    'preferred-color-scheme',
    'no-preference',
  );

  const isDark = useMediaQuery(COLOR_SCHEME_QUERY_DARK);
  const isLight = useMediaQuery(COLOR_SCHEME_QUERY_LIGHT);

  useEffect(() => {
    setPreferredColorScheme(isDark ? 'dark' : isLight ? 'light' : 'no-preference');
  }, [isDark, isLight, setPreferredColorScheme]);

  useEffect(() => {
    document.documentElement.className = userColorScheme !== 'no-preference' ? userColorScheme : preferredColorScheme;
  }, [userColorScheme, preferredColorScheme]);

  const setColorScheme = useCallback(
    (scheme: 'light' | 'dark') => {
      setUserColorScheme(scheme);
    },
    [setUserColorScheme],
  );

  const resetPreference = useCallback(() => {
    unsetUserColorScheme();
  }, [unsetUserColorScheme]);
  return (
    <ColorSchemeContext.Provider
      value={{
        preferredColorScheme: userColorScheme !== 'no-preference' ? userColorScheme : preferredColorScheme,
        setPreferredColorScheme: setColorScheme,
        isUserSet: userColorScheme !== 'no-preference',
        resetPreference,
      }}
    >
      {children}
    </ColorSchemeContext.Provider>
  );
};
