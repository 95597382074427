import { EDITABLE_INPUT_MODAL_ID } from '@pages/ChatFeed/RightColumn';
import AttachmentModalItem from '@pages/ChatFeed/RightColumn/Composer/AttachmentModal/AttachmentModalItem';
import MessageInput from '@pages/ChatFeed/RightColumn/Composer/MessageInput';
import { Alert, Button, Modal } from 'antd';
import { type FC, useRef } from 'react';

import { getAttachmentType, getFileSizeString } from '@/helpers/build-attachment.ts';
import usePrevious from '@/hooks/use-previous.ts';
import { type Attachment } from '@/types/attachment.ts';

import styles from './AttachmentModal.module.scss';

interface OwnProps {
  conversationId: number;
  attachments: Attachment[];
  getHtml: () => string;
  onCaptionUpdate: (html: string) => void;
  onSend: () => void;
  onClear: () => void;
}

const ATTACHMENT_MODAL_INPUT_ID = 'caption-input-text';
const ATTACHMENTS_TOTAL_SIZE_LIMIT = 100 * 1024 * 1024;
const ATTACHMENT_PHOTO_SIZE_LIMIT = 10 * 1024 * 1024;
const ATTACHMENT_FILE_SIZE_LIMIT = 50 * 1024 * 1024;

const AttachmentModal: FC<OwnProps> = ({ conversationId, attachments, getHtml, onCaptionUpdate, onSend, onClear }) => {
  // TODO: ability to add additional attachments and delete individual attachments
  const inputRef = useRef<HTMLDivElement>(null);

  const prevAttachments = usePrevious(attachments);
  const renderingAttachments = attachments.length ? attachments : prevAttachments;

  const isOpen = Boolean(attachments.length);

  const totalSizeOfAttachments = attachments.reduce((sum, attachment) => sum + attachment.size, 0);
  const individualFileExceedsSizeLimit = attachments.some((attachment) => {
    const type = getAttachmentType(attachment);
    return (
      (type === 'photo' && attachment.size >= ATTACHMENT_PHOTO_SIZE_LIMIT) ||
      attachment.size >= ATTACHMENT_FILE_SIZE_LIMIT
    );
  });
  const error =
    totalSizeOfAttachments >= ATTACHMENTS_TOTAL_SIZE_LIMIT
      ? `Attachments exceed the limit of ${getFileSizeString(ATTACHMENTS_TOTAL_SIZE_LIMIT)}`
      : individualFileExceedsSizeLimit
        ? `Attachment size limit is ${getFileSizeString(ATTACHMENT_PHOTO_SIZE_LIMIT)} for photos, and ${getFileSizeString(
            ATTACHMENT_FILE_SIZE_LIMIT,
          )} for other files.`
        : undefined;

  return (
    <Modal
      title="Send File"
      centered
      maskClosable={false}
      open={isOpen}
      onCancel={onClear}
      footer={
        <div className={styles.caption}>
          <MessageInput
            ref={inputRef}
            id={ATTACHMENT_MODAL_INPUT_ID}
            editableInputId={EDITABLE_INPUT_MODAL_ID}
            isActive={isOpen}
            conversationId={conversationId}
            getHtml={getHtml}
            onUpdate={onCaptionUpdate}
            onSend={onSend}
            placeholder="Add a caption..."
            canAutoFocus={Boolean(attachments.length)}
          />
          <div className={styles.sendWrapper}>
            <Button className={styles.send} type="primary" onClick={onSend} disabled={error !== undefined}>
              Send
            </Button>
          </div>
        </div>
      }
    >
      {error && <Alert style={{ margin: '1rem 0' }} message={error} type="error" />}
      <div className={styles.attachments}>
        {renderingAttachments?.map((attachment, i) => (
          <AttachmentModalItem attachment={attachment} index={i} key={attachment.uniqueId} />
        ))}
      </div>
    </Modal>
  );
};

export default AttachmentModal;
