import { validateFiles } from '@/utils/file.ts';

export default async function getFilesFromDataTransferItems(dataTransferItems: DataTransferItemList) {
  const files: File[] = [];

  function traverseFileTree(entry: FileSystemEntry | File, item: DataTransferItem): Promise<File | File[]> {
    return new Promise((resolve) => {
      if (entry instanceof File) {
        files.push(entry);
        resolve(entry);
      } else if (entry.isFile) {
        const itemFile = item.getAsFile();
        (entry as FileSystemFileEntry).file(
          (file) => {
            files.push(file);
            resolve(file);
          },
          () => {
            // iOS Safari can throw an error (probably only old versions)
            // https://stackoverflow.com/a/50059309
            if (itemFile !== null) {
              files.push(itemFile);
              resolve(itemFile);
            }
          },
        );
      } else if (entry.isDirectory) {
        const directoryReader = (entry as FileSystemDirectoryEntry).createReader();
        directoryReader.readEntries((entries) => {
          const entryPromises = [];
          for (const nestedEntry of entries) {
            entryPromises.push(traverseFileTree(nestedEntry, item));
          }
          resolve(Promise.all(entryPromises) as Promise<File[]>);
        });
      }
    });
  }

  const entryPromises: Promise<File | File[]>[] = [];
  for (const item of dataTransferItems) {
    if (item.kind === 'file') {
      const entry = item.webkitGetAsEntry() || item.getAsFile();
      if (entry) {
        entryPromises.push(traverseFileTree(entry, item));
      }
    }
  }

  await Promise.all(entryPromises);

  return validateFiles(files);
}
