import {
  ArrowDownOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileZipOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { type FC, useState } from 'react';

import { getFileSizeString } from '@/helpers/build-attachment.ts';
import { type ApiFile, fileExceedsTGAPILimit } from '@/types/messages.ts';
import buildClassName from '@/utils/build-class-name.ts';

import styles from './File.module.scss';

const FILE_EXTENSION_REGEX = /[^.][^/.]+$/;

const getExtensionFromFilename = (fileName: string) => fileName.match(FILE_EXTENSION_REGEX)?.[0];

const guessExtensionFromMimeType = (mimeType?: string) => {
  switch (mimeType) {
    case 'video/mp4':
      return 'mp4';
    default:
      return undefined;
  }
};

const getColorFromExtension = (extension?: string) => {
  switch (extension) {
    case 'apk':
    case 'xls':
    case 'xlsx':
    case 'ods':
      return 'green';
    case 'doc':
    case 'docx':
      return 'blue';
    case 'zip':
    case 'rar':
    case '7z':
    case 'tar':
    case 'gz':
    case 'bz2':
    case 'liz':
    case 'lz4':
    case 'lz5':
    case 'xz':
    case 'zst':
    case 'wim':
    case 'ppt':
    case 'pptx':
    case 'odp':
      return 'orange';
    case 'pdf':
    case 'xps':
      return 'red';
    case 'png':
      return 'light-blue';
    default:
      return 'default';
  }
};

const getIcon = (mimeType?: string) => {
  switch (mimeType) {
    case 'application/vnd.ms-excel': // .xls
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': // .xlsx
      return <FileExcelOutlined />;
    case 'application/msword': // .doc
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // .docx
      return <FileWordOutlined />;
    case 'application/zip': // .zip
      return <FileZipOutlined />;
    case 'application/pdf': // .pdf
      return <FilePdfOutlined />;
    case 'image/png':
    case 'image/jpeg':
    case 'image/webp':
    case 'image/gif':
    case 'image/avif':
    case 'image/bmp':
    case 'image/vnd.microsoft.icon':
    case 'image/svg+xml':
    case 'image/tiff':
      return <FileImageOutlined />;
    default:
      return <FileOutlined />;
  }
};

interface OwnProps {
  file: ApiFile;
  onDownloadClick?: () => Promise<void>;
  thumbnailUrl?: string;
}

const File: FC<OwnProps> = ({ file, onDownloadClick, thumbnailUrl }) => {
  const [shouldIconLoaderSpin, setShouldIconLoaderSpin] = useState(false);
  const extension = file.file_name
    ? getExtensionFromFilename(file.file_name)
    : guessExtensionFromMimeType(file.mime_type);
  const iconColor = getColorFromExtension(extension);
  const fileName = file.file_name || (extension ? `Unknown.${extension}` : 'Unknown');
  const isInteractive = onDownloadClick !== undefined && !fileExceedsTGAPILimit(file);
  const fileSizeString = file.file_size ? getFileSizeString(file.file_size) : undefined;

  const fullClassName = buildClassName(styles.file, isInteractive && styles.interactive);

  return (
    <div className={fullClassName}>
      <div
        className={buildClassName(styles.fileIconContainer, thumbnailUrl && styles.hasThumbnail)}
        {...(isInteractive && !shouldIconLoaderSpin
          ? {
              onClick: async (event) => {
                event.preventDefault();
                setShouldIconLoaderSpin(true);
                await onDownloadClick();
                setShouldIconLoaderSpin(false);
              },
            }
          : {})}
      >
        <div className={buildClassName(styles.fileIcon, !thumbnailUrl && iconColor)}>
          {thumbnailUrl && <img src={thumbnailUrl} style={{ maxHeight: '100%' }} alt={file.file_name} />}
          {!thumbnailUrl && getIcon(file.mime_type)}
          {!thumbnailUrl && extension && extension.length <= 4 && (
            <span className={styles.fileExt} dir="auto">
              {extension}
            </span>
          )}
        </div>
        {shouldIconLoaderSpin && (
          <div className={buildClassName(styles.fileProgress, !thumbnailUrl && iconColor)}>
            <LoadingOutlined />
          </div>
        )}
        {isInteractive && (
          <div className={buildClassName(styles.actionIcon, shouldIconLoaderSpin && styles.hidden)}>
            <ArrowDownOutlined />
          </div>
        )}
      </div>
      <div className={styles.fileInfo}>
        <div className={styles.fileTitle} dir="auto" title={fileName}>
          {fileName}
        </div>
        {fileSizeString && (
          <div className={styles.fileSubtitle} dir="auto">
            <span>{fileSizeString}</span>
            {/* LEGACY: for older messages we were replacing file_id with appropriate message */}
            {!isInteractive && <small>{file.file_id}</small>}
          </div>
        )}
      </div>
    </div>
  );
};

export default File;
