export class ValidationError extends Error {
  public readonly message: string;
  public readonly details?: Record<string, string[]>;

  constructor(message: string, details?: Record<string, string[]>) {
    super(message);
    this.message = message;
    this.details = details;
  }
}
