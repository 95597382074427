import { ActionFunction, json } from 'react-router-dom';

import { AuthContextType } from '@/context/auth.context';
import { apiRequest } from '@/services/fetcher';
import { OrganizationSettings } from '@/types/organization-settings';

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const data = await request.json();
    const intent = data.intent;
    delete data.intent;

    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1000);

    if (intent === 'update-organization') {
      const response = await apiRequest<OrganizationSettings>(
        `${import.meta.env.VITE_API_URL}/manage/organization/settings`,
        auth,
        {
          method: request.method,
          signal: request.signal,
          body: JSON.stringify(data),
        },
      );

      if (response instanceof Error) {
        throw response;
      }

      return response;
    }

    return json({ ok: false, statusText: 'Undefined intent' });
  }) satisfies ActionFunction;
