import type { AuthContextType } from '@/context/auth.context.tsx';
import type { AttachmentPayload, TypedSocket } from '@/context/socket.context.tsx';
import { getAttachmentType, getImageDimensions, getVideoDimensions } from '@/helpers/build-attachment.ts';
import { getStore } from '@/store';
import type { ConversationCache } from '@/store/types/conversation-cache.ts';
import type { Attachment } from '@/types/attachment.ts';
import type { LocalMessage } from '@/types/message.ts';
import { loadViewportMessages } from '@/utils/api/load-viewport-messages.ts';

export async function sendAttachments(
  auth: AuthContextType,
  socket: TypedSocket,
  conversationId: number,
  userId: number,
  attachments: Attachment[],
  caption?: string,
  authorName?: string,
  replyToId?: number,
) {
  const store = getStore<ConversationCache>(`conversation/${conversationId}`);

  if (store.state.nextCursor) {
    await loadViewportMessages(auth, 'after', conversationId, store.state.nextCursor, null);
  }

  const payload: AttachmentPayload[] = [];

  for (const attachment of attachments) {
    const type = getAttachmentType(attachment);
    const lastMessageId = store.state?.lastMessage?.id || store.state?.lastMessage?.localId;
    const newLocalId = (lastMessageId || 0) + 1;
    const extra_attributes: Record<string, string | number> = {
      file_id: attachment.blobUrl,
    };

    if (type === 'photo') {
      const dimensions = await getImageDimensions(attachment.blobUrl);
      extra_attributes.width = dimensions.width;
      extra_attributes.height = dimensions.height;
    } else if (type === 'video') {
      const dimensions = await getVideoDimensions(attachment.blobUrl);
      extra_attributes.width = dimensions.width;
      extra_attributes.height = dimensions.height;
      extra_attributes.file_name = attachment.filename;
      extra_attributes.mime_type = attachment.mimeType;
      extra_attributes.file_size = attachment.size;
    } else if (type === 'document' || type === 'audio') {
      extra_attributes.file_name = attachment.filename;
      extra_attributes.mime_type = attachment.mimeType;
      extra_attributes.file_size = attachment.size;
    }

    const localMessage: LocalMessage = {
      localId: newLocalId,
      type,
      user_id: userId,
      telegram_user_id: null,
      author_name: authorName || null,
      author_phone: null,
      body: caption || null,
      created_at: new Date().toISOString(),
      extra_attributes: type === 'photo' ? [extra_attributes] : extra_attributes,
      reply_to_id: replyToId,
    };
    payload.push({
      localId: newLocalId,
      filename: attachment.filename,
      type,
      size: attachment.size,
      file: attachment.file,
      caption: caption,
      reply_to_id: replyToId,
    });

    store.setState((state) => ({
      ...state,
      messageIds: state.messageIds?.concat(newLocalId),
      messagesById: {
        ...(state.messagesById || {}),
        [`${newLocalId}`]: localMessage,
      },
      lastMessage: localMessage,
      lastReadId: newLocalId,
    }));
  }

  socket.emit('attachments', {
    conversationId: conversationId,
    attachments: payload,
  });
}
