import type { LoaderFunction } from 'react-router-dom';

import type { AuthContextType } from '@/context/auth.context.tsx';
import fetchPaginatedUserMessages from '@/utils/api/fetchUserMessages.ts';

export const loader = (auth: AuthContextType) =>
  (async ({ request }) => {
    return await fetchPaginatedUserMessages(auth, 1, 10, {
      signal: request.signal,
    });
  }) satisfies LoaderFunction;
