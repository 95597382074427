import { Layout, Menu } from 'antd';
import { FC } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import useDocumentTitle from '@/hooks/use-document-title';

import styles from './Management.module.scss';

const Management: FC = () => {
  useDocumentTitle('Management');
  const path = '/management';
  const location = useLocation();

  return (
    <Layout className={styles.managementContainer}>
      <Layout.Sider>
        <Menu
          mode="inline"
          selectedKeys={[location.pathname.substring(location.pathname.lastIndexOf(path) + path.length + 1)]}
          items={[
            {
              label: (
                <NavLink to="conversations" unstable_viewTransition>
                  Conversations
                </NavLink>
              ),
              key: 'conversations',
            },
            {
              label: (
                <NavLink to="telegram-menu" unstable_viewTransition>
                  Telegram Menu
                </NavLink>
              ),
              key: 'telegram-menu',
            },
            {
              label: (
                <NavLink to="telegram-users" unstable_viewTransition>
                  Telegram Users
                </NavLink>
              ),
              key: 'telegram-users',
            },
            {
              label: (
                <NavLink to="message-templates" unstable_viewTransition>
                  Message Templates
                </NavLink>
              ),
              key: 'message-templates',
            },
            {
              label: (
                <NavLink to="users" unstable_viewTransition>
                  Users
                </NavLink>
              ),
              key: 'users',
            },
            {
              label: (
                <NavLink to="settings" unstable_viewTransition>
                  Settings
                </NavLink>
              ),
              key: 'settings',
            },
          ]}
        />
      </Layout.Sider>
      <Layout.Content className={styles.content}>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default Management;
