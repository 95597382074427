import { ActionFunction } from 'react-router-dom';

import { AuthContextType } from '@/context/auth.context.tsx';
import { apiRequest } from '@/services/fetcher.ts';
import { TelegramUser } from '@/types/telegram-user.ts';

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const data = await request.json();
    const intent = data.intent;
    delete data.intent;

    if (intent === 'update') {
      const userId = data.id;
      const response = await apiRequest<TelegramUser>(
        `${import.meta.env.VITE_API_URL}/manage/telegram-user/${userId}`,
        auth,
        {
          method: request.method,
          signal: request.signal,
          headers: request.headers,
          body: JSON.stringify(data),
        },
      );

      if (response instanceof Error) {
        throw response;
      }

      return response;
    }
  }) satisfies ActionFunction;
