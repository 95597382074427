import { GlobalOutlined, MoonOutlined, SunOutlined } from '@ant-design/icons';
import { Segmented } from 'antd';
import type { FC } from 'react';

import type { ColorSchemePreference } from '@/context/color-scheme.context.tsx';
import usePrefersColorScheme from '@/hooks/use-prefers-color-scheme.ts';

const ThemeSwitcher: FC = () => {
  const { preferredColorScheme, setPreferredColorScheme, isUserSet, resetPreference } = usePrefersColorScheme();

  const onChangeTheme = (value: ColorSchemePreference) => {
    if (value === 'no-preference') {
      resetPreference();
    } else {
      setPreferredColorScheme(value);
    }
  };

  return (
    <Segmented<ColorSchemePreference>
      options={[
        { title: 'System', icon: <GlobalOutlined />, value: 'no-preference' },
        { title: 'Light', icon: <SunOutlined />, value: 'light' },
        { title: 'Dark', icon: <MoonOutlined />, value: 'dark' },
      ]}
      value={isUserSet ? preferredColorScheme : 'no-preference'}
      onChange={onChangeTheme}
    />
  );
};

export default ThemeSwitcher;
