import { Alert, Button, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { type FC, useCallback } from 'react';
import { useFetcher } from 'react-router-dom';

import useFormResponse from '@/hooks/use-form-response.ts';
import type { MessageTemplateTranslation } from '@/types/message-template.ts';

interface OwnProps {
  open: boolean;
  onCreate: (translation: MessageTemplateTranslation) => void;
  onCancel: () => void;
  template_id: number;
  languages: Record<string, string>;
}

const TemplateAddTranslationForm: FC<OwnProps> = ({ open, onCreate, onCancel, template_id, languages }) => {
  const { submit, state, data: response } = useFetcher<Response | MessageTemplateTranslation>();
  const [form] = useForm<Omit<MessageTemplateTranslation, 'template_id'>>();
  const [formError] = useFormResponse<MessageTemplateTranslation>(form, response, true, (pureResponse) => {
    onCreate(pureResponse);
  });

  const onSubmit = useCallback(async () => {
    const values = await form.validateFields();
    submit(
      {
        intent: 'add-template-translation',
        ...Object.fromEntries(Object.entries(values).filter(([, value]) => value !== undefined)),
        template_id: `${template_id}`,
      },
      {
        method: 'post',
        encType: 'application/json',
      },
    );
  }, [template_id, form, submit]);

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <Modal
      open={open}
      title="Add Template Translation"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={state === 'submitting'} onClick={form.submit}>
          Add
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        disabled={state === 'submitting'}
        initialValues={{ language_code: Object.keys(languages)[0] }}
      >
        {formError && <Alert message={formError} style={{ margin: '24px 0' }} type="error" />}
        <Form.Item
          name="template"
          label="Text"
          rules={[
            {
              required: true,
              message: 'Please input the text for this translation!',
            },
            {
              pattern: /\S/,
              message: 'At least one character other than space is required!',
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="language_code"
          label="Language"
          rules={[
            {
              required: true,
              message: 'Please select the language!',
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={Object.entries(languages).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TemplateAddTranslationForm;
