import {
  DownloadOutlined,
  LoadingOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Image, Space, Spin } from 'antd';
import { type FC, useCallback, useEffect, useState } from 'react';

import useMedia from '@/hooks/use-media.ts';
import type { ApiPhoto } from '@/types/messages.ts';

import styles from './Photo.module.scss';

const SAFE_WIDTH_INSET = 32;

interface OwnProps {
  id: number;
  photos: ApiPhoto[];
}

const Photo: FC<OwnProps> = ({ id, photos }) => {
  const [shouldSpinnerRender, setShouldSpinnerRender] = useState(true);
  const [calculatedPhoto, setCalculatedPhoto] = useState<ApiPhoto>();
  const previewUrl = useMedia(id, calculatedPhoto !== undefined, calculatedPhoto?.file_id);
  const originalImage = photos?.[photos.length - 1];
  const imageUrl = useMedia(id, true, originalImage?.file_id);

  useEffect(() => {
    setShouldSpinnerRender(previewUrl === undefined);
  }, [previewUrl]);

  const measureDimensionsRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        const availableWidth =
          parseFloat(getComputedStyle(node).fontSize) *
            parseFloat(getComputedStyle(node).getPropertyValue('--max-width')) -
          SAFE_WIDTH_INSET;
        const closestWidthPhoto = photos.reduce((previous, current) => {
          if (!previous) {
            return current;
          }

          return Math.abs(previous.width - availableWidth) <= Math.abs(current.width - availableWidth)
            ? previous.width >= availableWidth
              ? previous
              : current
            : current;
        });

        setCalculatedPhoto(closestWidthPhoto);
      }
    },
    [photos],
  );

  const onDownload = useCallback(
    (imgUrl: string) => {
      const anchor = document.createElement('a');
      anchor.href = imgUrl;
      anchor.download = originalImage?.file_name || 'Image.jpg';
      anchor.dispatchEvent(new MouseEvent('click'));
      setTimeout(() => {
        URL.revokeObjectURL(imgUrl);
        anchor.remove();
      }, 100);
    },
    [originalImage?.file_name],
  );

  // noinspection JSUnusedGlobalSymbols
  return (
    <Spin indicator={<LoadingOutlined spin />} spinning={shouldSpinnerRender}>
      <div
        ref={measureDimensionsRef}
        className="media-inner"
        style={{ minWidth: photos?.[0]?.width, minHeight: photos?.[0]?.height }}
      >
        {previewUrl && (
          <Image
            preview={{
              src: imageUrl,
              toolbarRender: (
                _,
                {
                  image: { url },
                  transform: { scale },
                  actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset },
                },
              ) => (
                <Space size={12} className={styles.toolbarWrapper}>
                  <DownloadOutlined onClick={() => onDownload(url)} />
                  <SwapOutlined rotate={90} onClick={onFlipY} />
                  <SwapOutlined onClick={onFlipX} />
                  <RotateLeftOutlined onClick={onRotateLeft} />
                  <RotateRightOutlined onClick={onRotateRight} />
                  <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                  <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                  <UndoOutlined onClick={onReset} />
                </Space>
              ),
            }}
            src={previewUrl}
            style={{ maxWidth: '100%' }}
            placeholder={true}
          />
        )}
      </div>
    </Spin>
  );
};

export default Photo;
