import { ArrowDownOutlined, LoadingOutlined } from '@ant-design/icons';
import { loader } from '@pages/ChatFeed/RightColumn';
import { Badge, Button } from 'antd';
import type { FC } from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import type { ConversationHandler, MessageHandler } from '@/context/socket.context';
import useAuth from '@/hooks/use-auth';
import useLastCallback from '@/hooks/use-last-callback';
import useSocket from '@/hooks/use-socket';
import type { LoaderData } from '@/services/types/loader-data';
import { getStore } from '@/store';
import { selectInputMessageReplyInfo } from '@/store/selectors/messages.ts';
import type { ConversationCache } from '@/store/types/conversation-cache';
import { isUserConversation } from '@/types/conversation';
import { loadViewportMessages } from '@/utils/api/load-viewport-messages';
import buildClassName from '@/utils/build-class-name';
import { debounce } from '@/utils/schedulers';

import styles from './ScrollDownButton.module.scss';

interface OwnProps {
  isShown: boolean;
}

interface StateProps {
  unreadCount?: number;
  conversationId: number;
  composerHasEmbeddedMessage: boolean;
}

const scrollDebounced = debounce((cb) => cb(), 200, false);

const NonMemoScrollDownButton: FC<OwnProps & StateProps> = ({
  isShown,
  conversationId,
  unreadCount,
  composerHasEmbeddedMessage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const auth = useAuth();

  const handleClick = useLastCallback(async () => {
    if (!isShown) {
      return;
    }

    const store = getStore<ConversationCache>(`conversation/${conversationId}`);
    if (store.state.nextCursor) {
      setIsLoading(true);
    }

    while (store.state.nextCursor) {
      await loadViewportMessages(auth, 'after', conversationId, store.state.nextCursor, null);
    }

    // TODO: This may be prone to problems, ideally we need to detect container height changes in MessageList and keep scroll at bottom
    scrollDebounced(() => {
      const messagesContainer = elementRef.current!.parentElement!.querySelector<HTMLDivElement>('.MessageList')!;
      const messageElements = messagesContainer.querySelectorAll<HTMLDivElement>('.message-list-item');
      const lastMessageElement = messageElements[messageElements.length - 1];
      if (!lastMessageElement) {
        return;
      }

      const { offsetTop: elementTop, offsetHeight: elementHeight } = lastMessageElement;
      const { offsetHeight: containerHeight } = messagesContainer;

      messagesContainer.scrollTop = elementTop + elementHeight + 20 - containerHeight;
      setIsLoading(false);
    });
  });

  const className = buildClassName(
    styles.root,
    isShown ? styles.revealed : styles.hidden,
    composerHasEmbeddedMessage && styles.extraHigh,
  );

  return (
    <div ref={elementRef} className={className}>
      <Badge className={styles.badge} count={unreadCount}>
        <Button
          type="primary"
          shape="circle"
          size="large"
          icon={isLoading ? <LoadingOutlined /> : <ArrowDownOutlined />}
          onClick={handleClick}
        />
      </Badge>
    </div>
  );
};

const ScrollDownButton = memo(function ScrollDownButton({ isShown }: OwnProps) {
  const socket = useSocket();
  const { getUserId } = useAuth();
  const conversation = useLoaderData() as LoaderData<typeof loader>;
  const [unreadCount, setUnreadCount] = useState(Number(conversation.unread));

  useEffect(() => {
    setUnreadCount(Number(conversation.unread));
  }, [conversation.id, conversation.unread]);

  useEffect(() => {
    const onMessage: MessageHandler = (message) => {
      if (!isShown) {
        return;
      }

      setUnreadCount((count) => (message.user_id === getUserId() ? 0 : count + 1));
    };
    const onConversation: ConversationHandler = (conversation) => {
      if (isUserConversation(conversation)) {
        setUnreadCount(() => Number(conversation.unread));
      }
    };

    socket.on(`conversation/${Number(conversation.id)}/messages`, onMessage);
    socket.on(`conversation/${Number(conversation.id)}`, onConversation);

    return () => {
      socket.off(`conversation/${Number(conversation.id)}/messages`, onMessage);
      socket.off(`conversation/${Number(conversation.id)}`, onConversation);
    };
  }, [conversation.id, isShown, socket, getUserId]);

  const composerHasEmbeddedMessage = !!selectInputMessageReplyInfo();

  return (
    <NonMemoScrollDownButton
      isShown={isShown}
      conversationId={conversation.id}
      unreadCount={unreadCount}
      composerHasEmbeddedMessage={composerHasEmbeddedMessage}
    />
  );
});

export default ScrollDownButton;
