import { LockOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, InputRef, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useFetcher, useLocation, useNavigate } from 'react-router-dom';

import useAuth from '@/hooks/use-auth';

import styles from './Login.module.scss';

interface LoginFormValues {
  username: string;
  password: string;
}

const Login: FC = () => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { isAuthenticated } = useAuth();

  const [error, setError] = useState<string | null>(null);
  const [form] = useForm<LoginFormValues>();
  const { submit, state, data: response } = useFetcher<Response>();

  const usernameInputRef = useRef<InputRef>(null);
  const usernameInput = usernameInputRef.current;

  const onSubmit = (values: LoginFormValues) => {
    submit(
      Object.entries(values).reduce((formData, [key, value]) => {
        formData.append(key, value);

        return formData;
      }, new FormData()),
      {
        method: 'post',
        encType: 'application/x-www-form-urlencoded',
      },
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(locationState?.returnUrl?.pathname || '/', { replace: true });
    }
  }, [isAuthenticated, navigate, locationState]);

  useEffect(() => {
    if (state === 'submitting' && error !== null) {
      setError(null);
      return;
    }

    if (response !== undefined && response.status === 401) {
      setError('Username or password is incorrect.');
    }
  }, [state, response, error]);

  useEffect(() => {
    usernameInputRef.current?.focus();
  }, [usernameInput]);

  return (
    <Form className={styles.loginForm} form={form} onFinish={onSubmit} layout="vertical">
      <div className={styles.header}>
        <MessageOutlined className={styles.appIcon} />
        <Typography.Title level={2}>Sign In</Typography.Title>
      </div>
      {error !== null ? <Alert className={styles.formError} message={error} type="error" /> : null}
      <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
        <Input ref={usernameInputRef} prefix={<UserOutlined />} placeholder="Username" autoComplete="username" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
        <Input.Password
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
          autoComplete="current-password"
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            block={true}
            disabled={
              state === 'submitting' ||
              !form.isFieldsTouched(true) ||
              form.getFieldsError().some(({ errors }) => errors.length > 0)
            }
            loading={state === 'submitting'}
          >
            Log in
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default Login;
