import type { AuthContextType } from '@/context/auth.context.tsx';
import { apiRequest, isResponse } from '@/services/fetcher.ts';
import type { TelegramUser } from '@/types/telegram-user.ts';
import type { OffsetPaginatedResponse } from '@/utils/api/api-response.ts';

async function fetchTelegramUsers(
  auth: AuthContextType,
  query: string | null = null,
  page = 1,
  pageSize = 10,
  options?: RequestInit,
) {
  let url = `${import.meta.env.VITE_API_URL}/manage/telegram-user?page=${page}&limit=${pageSize}`;
  if (query) {
    url = `${url}&${query}`;
  }
  const response = await apiRequest<OffsetPaginatedResponse<TelegramUser>>(url, auth, options);

  if (isResponse(response)) {
    return {
      status: response.status,
      statusText: response.statusText,
    } as Response;
  }

  if (response instanceof Error) {
    throw response;
  }

  return response;
}

export default fetchTelegramUsers;
