import { type ActionFunction, json } from 'react-router-dom';

import type { AuthContextType } from '@/context/auth.context';
import { apiRequest, isResponse } from '@/services/fetcher';
import { ConflictError } from '@/services/types/conflict-error.ts';
import { ValidationError } from '@/services/types/validation-error.ts';
import type { TelegramMenuItem } from '@/types/telegram-menu-item.ts';
import type { TelegramMenuItemTranslation } from '@/types/telegram-menu-item-translation.ts';

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const formData = await request.formData();
    const intent = formData.get('intent');
    formData.delete('intent');

    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1000);

    if (intent === 'add-menu-item') {
      const response = await apiRequest<TelegramMenuItem>(
        `${import.meta.env.VITE_API_URL}/manage/telegram-menu`,
        auth,
        {
          method: request.method,
          signal: request.signal,
          body: formData,
        },
      );

      if (response instanceof ValidationError) {
        return json({ ok: false, statusText: response.message });
      }

      if (response instanceof Error) {
        throw response;
      }

      if (isResponse(response)) {
        return {
          status: response.status,
          statusText: response.statusText,
        } as Response;
      }

      return response;
    } else if (intent === 'add-menu-item-translation') {
      const itemId = formData.get('item_id');
      const response = await apiRequest<TelegramMenuItemTranslation>(
        `${import.meta.env.VITE_API_URL}/manage/telegram-menu/${itemId}/translation`,
        auth,
        {
          method: request.method,
          signal: request.signal,
          body: formData,
        },
      );

      if (response instanceof ConflictError) {
        return json({ ok: false, statusText: response.message });
      }

      if (response instanceof Error) {
        throw response;
      }

      if (isResponse(response)) {
        return {
          status: response.status,
          statusText: response.statusText,
        } as Response;
      }

      return response;
    } else if (intent === 'update-menu-item') {
      const itemId = formData.get('id');
      const response = await apiRequest<TelegramMenuItem>(
        `${import.meta.env.VITE_API_URL}/manage/telegram-menu/${itemId}`,
        auth,
        {
          method: request.method,
          signal: request.signal,
          body: formData,
        },
      );

      if (response instanceof Error) {
        throw response;
      }

      return response;
    } else if (intent === 'update-translation') {
      const itemId = formData.get('item_id');
      const languageCode = formData.get('language_code_previous');
      formData.delete('language_code_previous');
      const response = await apiRequest<TelegramMenuItem>(
        `${import.meta.env.VITE_API_URL}/manage/telegram-menu/${itemId}/translation/${languageCode}`,
        auth,
        {
          method: request.method,
          signal: request.signal,
          body: formData,
        },
      );

      if (response instanceof Error) {
        throw response;
      }

      return response;
    } else if (intent === 'delete-menu-item') {
      const itemId = formData.get('id');
      const response = await apiRequest<Response>(
        `${import.meta.env.VITE_API_URL}/manage/telegram-menu/${itemId}`,
        auth,
        {
          method: request.method,
          signal: request.signal,
        },
      );

      if (response instanceof Error) {
        throw response;
      }

      return json({ ok: true, statusText: 'No Content' });
    } else if (intent === 'delete-translation') {
      const itemId = formData.get('item_id');
      const languageCode = formData.get('language_code');
      const response = await apiRequest<Response>(
        `${import.meta.env.VITE_API_URL}/manage/telegram-menu/${itemId}/translation/${languageCode}`,
        auth,
        {
          method: request.method,
          signal: request.signal,
        },
      );

      if (response instanceof Error) {
        throw response;
      }

      return json({ ok: true, statusText: 'No Content' });
    } else if (intent === 'notify-telegram') {
      const response = await apiRequest<Response>(
        `${import.meta.env.VITE_API_URL}/manage/telegram/keyboard-change`,
        auth,
        {
          method: request.method,
          signal: request.signal,
        },
      );

      if (response instanceof Error) {
        throw response;
      }

      return json({ ok: true, statusText: 'No Content' });
    } else if (intent === 'swap-sort-order') {
      const srcId = formData.get('src_id');

      const response = await apiRequest<Response>(
        `${import.meta.env.VITE_API_URL}/manage/telegram-menu/${srcId}/swap-order`,
        auth,
        {
          method: request.method,
          signal: request.signal,
          body: formData,
        },
      );

      if (response instanceof Error) {
        throw response;
      }

      return json({ ok: true, statusText: 'No Content' });
    }

    return json({ ok: false, statusText: 'Undefined intent' });
  }) satisfies ActionFunction;
