import { DeleteOutlined, EnterOutlined, PhoneOutlined, StopOutlined } from '@ant-design/icons';
import Sticker from '@pages/ChatFeed/RightColumn/MessageList/MessageListContent/Message/Sticker';
import { App, Button, Dropdown, MenuProps, Tooltip } from 'antd';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useRef } from 'react';

import { type ObserveFn, useOnIntersect } from '@/hooks/use-intersection-observer';
import useLastCallback from '@/hooks/use-last-callback.ts';
import useSocket from '@/hooks/use-socket.ts';
import EmbeddedMessage from '@/pages/ChatFeed/components/EmbeddedMessage';
import { setInputMessageReplyInfo } from '@/store/reducers/messages.ts';
import { selectIsMessageFocused } from '@/store/selectors/messages.ts';
import type { Message as ApiMessage } from '@/types/message';
import {
  fileExceedsTGAPILimit,
  isAudioLikeMessage,
  isDocumentMessage,
  isFileMessage,
  isPhotoMessage,
  isPlayableVideoMessage,
  isStickerMessage,
  isVideoLikeMessage,
} from '@/types/messages.ts';
import { deleteMessage } from '@/utils/api/delete-message.ts';
import buildClassName from '@/utils/build-class-name.ts';
import { focusMessage } from '@/utils/ui/focus-message.ts';

import Audio from './Audio';
import Document from './Document';
import styles from './Message.module.scss';
import MessageMeta from './MessageMeta';
import MessageText from './MessageText';
import Photo from './Photo';
import Video from './Video';

const NBSP = '\u00A0';

function getMessageHtmlId(messageId: number) {
  return `message${messageId.toString().replace('.', '-')}`;
}

function isReplyToMessage(message: ApiMessage) {
  return !!message.reply_to_id;
}

function isEditedMessage(message: ApiMessage) {
  return !!message.edited_message_id;
}

interface MessagePositionProperties {
  isFirstInGroup: boolean;
  isLastInGroup: boolean;
  isLastInList: boolean;
}

interface OwnProps extends MessagePositionProperties {
  message: ApiMessage;
  observeIntersectionForBottom: ObserveFn;
}

const Message: FC<OwnProps> = ({
  message,
  observeIntersectionForBottom,
  isFirstInGroup,
  isLastInGroup,
  isLastInList,
}) => {
  const bottomMarkerRef = useRef<HTMLDivElement>(null);
  const { modal } = App.useApp();
  const socket = useSocket();

  useOnIntersect(bottomMarkerRef, observeIntersectionForBottom);

  const messageId = (message.id || message.localId) as number;
  const isOwn = message.user_id !== null;
  const hasText = message.body !== null;
  const hasMessageReply = isReplyToMessage(message);
  const hasEditedMessage = isEditedMessage(message);
  const hasSubheader = hasMessageReply || hasEditedMessage;
  const isFocused = selectIsMessageFocused(message, message.conversation_id);

  function renderSenderName() {
    if (!isFirstInGroup) {
      return undefined;
    }

    return (
      <div className={styles.messageTitle} dir="ltr">
        {message.author_name ? (
          <span className={styles.messageTitleName} dir="ltr">
            {message.author_name}
          </span>
        ) : (
          NBSP
        )}
        {message.author_phone && (
          <a
            className={styles.messageTitlePhone}
            href={`tel:${message.author_phone}`}
            title={`Call ${message.author_name}`}
          >
            <PhoneOutlined />
          </a>
        )}
      </div>
    );
  }

  function renderMessageText() {
    return message.deleted_at ? (
      <div style={{ color: '#bcc0c1' }}>
        <StopOutlined style={{ marginRight: '0.3rem' }} />
        This{' '}
        <Tooltip title={message.body}>
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>message</span>
        </Tooltip>{' '}
        was deleted from Telegram by {message.deleted_author_name}.
      </div>
    ) : (
      <MessageText message={message.body} />
    );
  }

  function renderContent() {
    return (
      <div className="content-inner" dir="auto">
        {(isDocumentMessage(message) ||
          (isFileMessage(message) && fileExceedsTGAPILimit(message.extra_attributes))) && (
          <Document id={message.id} document={message.extra_attributes} />
        )}
        {isAudioLikeMessage(message) && <Audio id={message.id} audio={message.extra_attributes} />}
        {isVideoLikeMessage(message) && isPlayableVideoMessage(message) && (
          <Video id={message.id} video={message.extra_attributes} />
        )}
        {isPhotoMessage(message) && <Photo id={message.id} photos={message.extra_attributes} />}
        {isStickerMessage(message) && <Sticker id={message.id} sticker={message.extra_attributes} />}
        {hasText && (
          <div className={styles.textContent} dir="auto">
            {renderMessageText()}
          </div>
        )}
      </div>
    );
  }

  const handleReplyClick = useLastCallback((): void => {
    if (!message.replyMessage || !message.replyMessage.conversation_id) {
      return;
    }

    focusMessage(message.replyMessage.conversation_id, message.replyMessage.id || message.replyMessage.localId);
  });

  const handleEditedClick = useLastCallback((): void => {
    if (!message.editedMessage || !message.editedMessage.conversation_id) {
      return;
    }

    focusMessage(message.editedMessage.conversation_id, message.editedMessage.id || message.editedMessage.localId);
  });

  const created_at = dayjs(message.created_at);
  const canBeRepliedTo = message.tg_message_id && !message.deleted_at;
  const isDeleted = message.deleted_by || message.deleted_at;
  const canBeDeleted =
    isOwn && message.type === 'text' && !isDeleted && created_at.add(48, 'hours').isAfter(new Date());

  const addReplyToMessageInfo = useLastCallback(() => {
    setInputMessageReplyInfo(message);
  });

  const contextMenuItems: MenuProps['items'] = [
    ...(canBeRepliedTo
      ? [
          {
            key: 'reply',
            label: 'Reply',
            icon: <EnterOutlined style={{ transform: 'scaleX(-1) rotate(180deg)' }} />,
          },
        ]
      : []),
    ...(canBeDeleted
      ? [
          {
            key: 'delete',
            label: 'Delete',
            icon: <DeleteOutlined />,
          },
        ]
      : []),
  ];

  const onContextMenuClick: MenuProps['onClick'] = async ({ key }) => {
    if (key === 'reply') {
      addReplyToMessageInfo();
      return;
    } else if (key === 'delete') {
      const confirmed = await modal.confirm({
        title: 'Delete message',
        content: 'Are you sure you want to delete this message?',
        okText: 'Yes',
      });
      if (confirmed) {
        deleteMessage(socket, messageId);
      }
    }
  };

  const containerClassName = buildClassName(
    'message-list-item',
    styles.message,
    isFirstInGroup && styles.firstInGroup,
    isLastInGroup && styles.lastInGroup,
    isLastInList && styles.lastInList,
    isOwn && styles.own,
    isOwn && 'own',
    hasMessageReply && styles.hasReply,
    isFocused && styles.focused,
  );

  const contentClassName = buildClassName(
    styles.messageContent,
    styles.hasBackground,
    message.type,
    hasSubheader && styles.withSubheader,
  );

  return (
    // TODO: remove logging `data-is-local`
    <div
      id={getMessageHtmlId(messageId)}
      className={containerClassName}
      data-is-local={!message.id}
      data-message-id={messageId}
      onDoubleClick={canBeRepliedTo ? addReplyToMessageInfo : undefined}
    >
      <div ref={bottomMarkerRef} className="bottom-marker" data-message-id={messageId} />
      <Dropdown
        trigger={['contextMenu']}
        menu={{
          items: contextMenuItems,
          onClick: onContextMenuClick,
        }}
      >
        <div className={contentClassName}>
          {renderSenderName()}
          {hasSubheader && (
            <div className={styles.messageSubheader}>
              {hasMessageReply && (
                <EmbeddedMessage message={message.replyMessage} onClick={handleReplyClick} isEditedMessage={false} />
              )}
              {hasEditedMessage && (
                <EmbeddedMessage message={message.editedMessage} onClick={handleEditedClick} isEditedMessage={true} />
              )}
            </div>
          )}
          {renderContent()}
          {canBeRepliedTo && (
            <Button type="link" className={styles.replyButton} onClick={addReplyToMessageInfo}>
              Reply
            </Button>
          )}
          <MessageMeta className={styles.messageMeta} message={message} isOwn={isOwn} />
        </div>
      </Dropdown>
    </div>
  );
};

export default Message;
