import { useSelector } from '@/store';
import type { FocusedMessage } from '@/store/types/focused-message.ts';
import { InputMessageReplyInfo } from '@/store/types/input-message-reply-info.ts';
import type { Message } from '@/types/message.ts';

export function selectIsMessageFocused(message: Message, conversationId?: number) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSelector<FocusedMessage, boolean>('focusedMessage', (state) => {
    if (state === undefined || conversationId !== state.conversationId) {
      return false;
    }

    return state.messageId ? state.messageId === message.id || state.messageId === message.localId : false;
  });
}

export function selectInputMessageReplyInfo() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSelector<InputMessageReplyInfo, Message | undefined>('inputMessageReplyInfo', (state) => {
    return state?.message;
  });
}
