import { LockOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import type { ConversationHandler, MessageHandler } from '@/context/socket.context.tsx';
import useAuth from '@/hooks/use-auth.ts';
import useSocket from '@/hooks/use-socket.ts';
import { useStore } from '@/store';
import { ConversationCache } from '@/store/types/conversation-cache.ts';
import { isUserConversation } from '@/types/conversation.ts';

import styles from './ConversationListItem.module.scss';

interface OwnProps {
  id: number;
  title: string;
  readonly: boolean;
  text: string | null;
  firstUnreadId: number | null;
  active: boolean;
  unreadCount: number;
  onUpdatedAt: (id: number, date: string) => void;
  onUnreadUpdated: (id: number, unreadCount: number) => void;
  onAuthorizationUpdated: (id: number, authorized: boolean) => void;
}

const ConversationListItem: FC<OwnProps> = ({
  id,
  title,
  readonly,
  text,
  firstUnreadId,
  active,
  unreadCount,
  onUpdatedAt,
  onUnreadUpdated,
  onAuthorizationUpdated,
}) => {
  const { getUserId } = useAuth();
  const socket = useSocket();
  const [latestMessage, setLatestMessage] = useState(text);
  const [, setStore] = useStore<ConversationCache>(`conversation/${id}`);

  useEffect(() => {
    setStore((state) => {
      if (!state) {
        return state;
      }
      const cursor =
        !state.nextCursor && state.lastReadId && firstUnreadId && state.lastReadId < firstUnreadId
          ? firstUnreadId
          : state.nextCursor;

      return {
        ...state,
        nextCursor: cursor,
      };
    });
  }, [firstUnreadId, setStore]);

  useEffect(() => {
    const onMessage: MessageHandler = (message) => {
      onUpdatedAt(Number(id), message.created_at);
      switch (message.type) {
        case 'audio':
        case 'video':
        case 'voice':
        case 'photo':
          setLatestMessage('Media message');
          break;
        case 'document':
          setLatestMessage('Attachment');
          break;
        case 'text':
          setLatestMessage(message.deleted_at && message.deleted_by ? 'This message was deleted.' : message.body);
          break;
        default:
          setLatestMessage(message.body);
      }
      // We do not update unread if it's message update when message is deleted
      if (!(message.deleted_at && message.deleted_by)) {
        onUnreadUpdated(Number(id), message.user_id === getUserId() ? 0 : unreadCount + 1);
      }
      if (!active && message.id) {
        setStore((state) => ({
          ...state,
          nextCursor: !state?.nextCursor ? message.id : state.nextCursor,
        }));
      }
    };

    const onConversation: ConversationHandler = (conversation) => {
      if (isUserConversation(conversation)) {
        onUnreadUpdated(Number(id), Number(conversation.unread));
      }

      if (conversation.is_authorized !== !readonly) {
        onAuthorizationUpdated(Number(id), conversation.is_authorized);
      }
    };

    if (!readonly) {
      socket.on(`conversation/${Number(id)}/messages`, onMessage);
    }
    socket.on(`conversation/${Number(id)}`, onConversation);

    return () => {
      socket.off(`conversation/${Number(id)}/messages`, onMessage);
      socket.off(`conversation/${Number(id)}`, onConversation);
    };
  }, [
    id,
    socket,
    readonly,
    onUpdatedAt,
    getUserId,
    onUnreadUpdated,
    unreadCount,
    onAuthorizationUpdated,
    setStore,
    active,
  ]);

  return (
    <div className={[styles.conversationListItem, active ? styles.active : ''].join(' ')} role="link" tabIndex={0}>
      <div className={styles.conversationInfo}>
        <div className={styles.conversationTitle}>{title}</div>
        <div className={styles.conversationSnippet}>{latestMessage}</div>
      </div>
      {unreadCount > 0 ? <Badge className={styles.badge} count={unreadCount} /> : null}
      {readonly ? <LockOutlined className={styles.lock} /> : null}
    </div>
  );
};

export default ConversationListItem;
