import { EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import PaginatedFilteredTable, {
  type ColumnsType,
  type PaginatedFilteredTableRef,
} from '@components/PaginatedFilteredTable';
import EditableCell, { type EditableCellProps } from '@pages/Management/EditableCell/EditableCell.tsx';
import { Button, Form, Space, Tag } from 'antd';
import { useForm } from 'antd/es/form/Form';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getFormattedNumber } from 'react-phone-hooks';
import { useFetcher, useLoaderData } from 'react-router-dom';

import type { LoaderData } from '@/services/types/loader-data.ts';
import type { TelegramUser } from '@/types/telegram-user.ts';

import type { loader } from './loader.ts';

const isErrorResponse = (response: Response | TelegramUser): response is Response => {
  return 'ok' in response && !response.ok;
};

interface UserForm {
  full_name: string;
  phone_number: string | null;
}

const TelegramUserManagement: FC = () => {
  const loaderData = useLoaderData() as LoaderData<typeof loader>;
  const [data, setData] = useState(loaderData);
  const [users, setUsers] = useState(data.data);
  const [loading, setLoading] = useState(false);
  const { submit, state, data: response } = useFetcher<Response | TelegramUser>();
  const [form] = useForm<UserForm>();
  const [editingKey, setEditingKey] = useState<number>();
  const tableRef = useRef<PaginatedFilteredTableRef>(null);

  useEffect(() => {
    setLoading(state === 'submitting' || state === 'loading');
  }, [state]);

  useEffect(() => {
    setData(loaderData);
  }, [loaderData]);

  useEffect(() => {
    setUsers(data.data);
  }, [data.data]);

  useEffect(() => {
    if (response === undefined) {
      return;
    }

    if (isErrorResponse(response)) {
      form.setFields([
        {
          name: 'full_name',
          errors: [response.statusText],
        },
      ]);
      return;
    }

    setEditingKey(undefined);
  }, [form, response]);

  const isEditing = (record: TelegramUser) => record.id === editingKey;
  const onSave = useCallback(
    async (id: number) => {
      try {
        const values = await form.validateFields();
        const initialUser = users?.find((user) => user.id === id);
        if (initialUser === undefined) {
          return;
        }

        const changedValues = Object.fromEntries(
          Object.entries(values)
            .filter(([key, value]) => initialUser[key as keyof TelegramUser] !== value && value !== undefined)
            .map(([key, value]) => [key, value === undefined || value === '' ? null : value]),
        );

        if (Object.keys(changedValues).length > 0) {
          submit(
            {
              intent: 'update',
              id: `${id}`,
              ...changedValues,
            },
            {
              method: 'patch',
              encType: 'application/json',
            },
          );
        }
      } catch (error) {
        console.log('Validation Failed:', error);
      }
    },
    [form, submit, users],
  );

  const columns: ColumnsType<TelegramUser> = [
    {
      key: 'full_name',
      title: 'Full Name',
      dataIndex: 'full_name',
      filterable: {
        placeholder: 'full name',
      },
      onCell: (record): Omit<EditableCellProps, 'children'> => ({
        dataIndex: 'full_name',
        inputType: 'text',
        title: 'Full Name',
        editing: isEditing(record),
        style: { minWidth: '170px' },
      }),
    },
    {
      key: 'telegram_groups.name',
      title: 'Conversation Name(s)',
      dataIndex: 'conversations',
      filterable: {
        placeholder: 'conversation name',
      },
      render: (value: string[] | null) =>
        value ? (
          <>
            {value.map((name, index) => (
              <Tag key={index}>{name}</Tag>
            ))}
          </>
        ) : null,
    },
    {
      key: 'phone_number',
      title: 'Phone Number',
      dataIndex: 'phone_number',
      filterable: {
        placeholder: 'phone number',
      },
      width: 230,
      onCell: (record): Omit<EditableCellProps, 'children'> => ({
        dataIndex: 'phone_number',
        inputType: 'phone-number',
        title: 'Phone Number',
        required: false,
        placeholder: '+1 (999) 999-9999',
        editing: isEditing(record),
      }),
      render: (value: string | null) => (value ? getFormattedNumber(value.replace(/^\+/, '')) : null),
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 80,
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <Space size="middle">
            {editable ? (
              <>
                <Button type="primary" icon={<SaveOutlined />} title="Save" onClick={() => onSave(record.id)} />
                <Button
                  type="default"
                  icon={<StopOutlined />}
                  title="Cancel"
                  onClick={() => setEditingKey(undefined)}
                />
              </>
            ) : (
              <>
                <Button
                  type="default"
                  icon={<EditOutlined />}
                  onClick={() => {
                    form.setFieldsValue({
                      full_name: record.full_name,
                      phone_number: record.phone_number,
                    });
                    setEditingKey(record.id);
                  }}
                />
              </>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Form form={form} component={false}>
      <PaginatedFilteredTable<TelegramUser>
        ref={tableRef}
        data={data}
        columns={columns}
        rowKey="id"
        loading={loading}
        rowClassName="editable-row"
        components={{ body: { cell: EditableCell } }}
      />
    </Form>
  );
};

export default TelegramUserManagement;
