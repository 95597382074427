export interface Conversation {
  id: number;
  name: string;
  is_authorized: boolean;
  language_code: string;
}

export interface UserConversation extends Conversation {
  cursor: number | null;
  unread: number;
}

export const isUserConversation = (conversation: Conversation | UserConversation): conversation is UserConversation =>
  'unread' in conversation;

export interface ConversationListItem extends Conversation {
  firstUnreadId: number | null;
  unread: number;
  latestMessage: string | null;
  time: string | null;
}
