import { useLayoutEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useLayoutEffectWithPrevDeps = <const T extends readonly any[]>(
  cb: (args: T | readonly []) => void,
  dependencies: T,
) => {
  const prevDepsRef = useRef<T>();

  return useLayoutEffect(() => {
    const prevDeps = prevDepsRef.current;
    prevDepsRef.current = dependencies;

    return cb(prevDeps || []);
  }, [cb, dependencies]);
};

export default useLayoutEffectWithPrevDeps;
