import '@assets/style.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { AuthProvider } from '@/context/auth.context';
import { ColorSchemeProvider } from '@/context/color-scheme.context.tsx';

import App from './app/App';

const ROOT = document.getElementById('react-root') as HTMLElement;

createRoot(ROOT).render(
  <React.StrictMode>
    <ColorSchemeProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ColorSchemeProvider>
  </React.StrictMode>,
);
