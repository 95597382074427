import { Empty, Spin } from 'antd';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useRef } from 'react';

import { type MessageHandler } from '@/context/socket.context';
import { appendReplyAndEditedMessages } from '@/helpers/append-reply-and-edited-messages.ts';
import { groupMessages } from '@/helpers/group-messages';
import useAuth from '@/hooks/use-auth';
import useLastCallback from '@/hooks/use-last-callback';
import useLayoutEffectWithPrevDeps from '@/hooks/use-layout-effect-with-prev-deps';
import useSocket from '@/hooks/use-socket';
import { useStateRef } from '@/hooks/use-state-ref';
import useSyncEffect from '@/hooks/use-sync-effect';
import { getStore, useSelector } from '@/store';
import { type ConversationCache } from '@/store/types/conversation-cache';
import { type UserConversation } from '@/types/conversation';
import { type Message } from '@/types/message';
import { loadViewportMessages } from '@/utils/api/load-viewport-messages';
import buildClassName from '@/utils/build-class-name';
import { debounce } from '@/utils/schedulers';

import styles from './MessageList.module.scss';
import MessageListContent from './MessageListContent';

interface OwnProps {
  conversationId: number;
  canPost: boolean;
  onFabToggle: (shouldShow: boolean) => void;
  onNotchToggle: (shouldShow: boolean) => void;
}

interface StateProps {
  messageIds?: number[];
  messagesById?: Record<number, Message>;
  lastReadId?: number;
}

const BOTTOM_THRESHOLD = 50;
const SCROLL_DEBOUNCE = 200;

const runDebouncedForScroll = debounce((cb) => cb(), SCROLL_DEBOUNCE, false);

const NonMemoMessageList: FC<OwnProps & StateProps> = ({
  conversationId,
  canPost,
  onFabToggle,
  onNotchToggle,
  messageIds,
  messagesById,
  lastReadId,
}) => {
  const auth = useAuth();

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollOffsetRef = useRef<number>(0);

  const anchorIdRef = useRef<string>();
  const anchorTopRef = useRef<number>();
  const listItemElementsRef = useRef<HTMLDivElement[]>();
  const memoLastReadIdRef = useRef<number | null>();
  const memoUnreadDividerAfterIdRef = useRef<number | null>();
  // const memoFocusingIdRef = useRef<number>();
  const isScrollTopJustUpdatedRef = useRef(false);

  const areMessagesLoaded = Boolean(messageIds);

  useSyncEffect(() => {
    memoLastReadIdRef.current = lastReadId || null;
  }, [lastReadId]);

  useSyncEffect(() => {
    if (areMessagesLoaded) {
      memoUnreadDividerAfterIdRef.current = memoLastReadIdRef.current;
    }
  }, [areMessagesLoaded]);

  const messageGroups = useMemo(() => {
    if (!messageIds?.length || !messagesById) {
      return undefined;
    }

    const listedMessages = appendReplyAndEditedMessages(messageIds, messagesById);

    return listedMessages.length ? groupMessages(listedMessages, memoLastReadIdRef.current) : undefined;
  }, [messageIds, messagesById]);

  const loadMoreAround = useMemo(() => {
    return debounce(() => loadViewportMessages(auth, 'both', conversationId, lastReadId), 1000, true, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, conversationId, lastReadId, messageIds]);

  const isScrolled = true;

  const handleScroll = useLastCallback(() => {
    if (isScrollTopJustUpdatedRef.current) {
      isScrollTopJustUpdatedRef.current = false;
    }

    const container = containerRef.current;
    if (!container) {
      return;
    }

    // Set isScrolled

    runDebouncedForScroll(() => {
      if (!container.parentElement) {
        return;
      }

      scrollOffsetRef.current = container.scrollHeight - container.scrollTop;

      // Save scroll offset to local cache?
    });
  });

  // Initial message loading
  useEffect(() => {
    if (!loadMoreAround) {
      return;
    }

    // const container = containerRef.current!;
    if (!messageIds) {
      loadMoreAround();
    }
  }, [messageIds, loadMoreAround]);

  const rememberScrollPositionRef = useStateRef(() => {
    if (!messageIds || !listItemElementsRef.current) {
      return;
    }

    const preservedItemElements = listItemElementsRef.current.filter((element) =>
      messageIds.includes(Number(element.dataset.messageId)),
    );
    const anchor = preservedItemElements[1];
    if (!anchor) {
      return;
    }

    anchorIdRef.current = anchor.id;
    anchorTopRef.current = anchor.getBoundingClientRect().top;
  });

  useSyncEffect(() => rememberScrollPositionRef.current(), [messageIds, rememberScrollPositionRef]);

  useLayoutEffectWithPrevDeps(
    ([prevMessageIds]) => {
      if (messageIds === prevMessageIds) {
        return;
      }

      const container = containerRef.current!;
      listItemElementsRef.current = Array.from(container.querySelectorAll<HTMLDivElement>('.message-list-item'));

      const hasLastMessageChanged =
        messageIds !== undefined &&
        prevMessageIds !== undefined &&
        messageIds[messageIds.length - 1] !== prevMessageIds[prevMessageIds.length - 1];
      const hasViewportShifted = messageIds?.[0] !== prevMessageIds?.[0] && messageIds?.length === 60;
      const wasMessageAdded = hasLastMessageChanged && !hasViewportShifted;

      const { scrollHeight, offsetHeight } = container;
      const scrollOffset = scrollOffsetRef.current;

      const bottomOffset = scrollOffset - offsetHeight;
      const isAtBottom = bottomOffset <= BOTTOM_THRESHOLD;

      const unreadDivider =
        memoUnreadDividerAfterIdRef.current && container.querySelector<HTMLDivElement>('.unread-divider');
      const shouldScrollToBottom =
        (memoLastReadIdRef.current &&
          messageIds &&
          (messageIds[messageIds.length - 2] === memoLastReadIdRef.current ||
            messageIds[messageIds.length - 1] === memoLastReadIdRef.current)) ||
        false;

      let newScrollTop!: number;

      if (isAtBottom && wasMessageAdded && shouldScrollToBottom) {
        newScrollTop = scrollHeight - offsetHeight;
      } else if (unreadDivider) {
        newScrollTop = Math.min(unreadDivider.offsetTop - 10, scrollHeight - scrollOffset);
      } else {
        newScrollTop = scrollHeight - scrollOffset;
      }

      container.scrollTop = Math.ceil(newScrollTop);
      scrollOffsetRef.current = Math.max(Math.ceil(scrollHeight - newScrollTop), offsetHeight);
    },
    [messageIds],
  );

  const loading = messageIds === undefined && messageGroups === undefined;
  const hasMessages = messageIds !== undefined && messageGroups !== undefined;

  const className = buildClassName(
    'MessageList',
    styles.messageList,
    'custom-scroll',
    !canPost && 'no-composer',
    isScrolled && 'scrolled',
    loading && styles.loading,
  );

  return (
    <div ref={containerRef} className={className} onScroll={handleScroll}>
      {loading ? (
        <Spin />
      ) : !hasMessages ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No messages" />
      ) : (
        <MessageListContent
          conversationId={conversationId}
          messageIds={messageIds || []}
          messageGroups={messageGroups}
          containerRef={containerRef}
          memoLastReadIdRef={memoLastReadIdRef}
          memoUnreadDividerAfterIdRef={memoUnreadDividerAfterIdRef}
          hasUnread={Boolean(lastReadId)}
          onFabToggle={onFabToggle}
          onNotchToggle={onNotchToggle}
        />
      )}
    </div>
  );
};

const MessageList = memo(function MessageList({
  conversation,
  canPost,
  onFabToggle,
  onNotchToggle,
}: Omit<OwnProps, 'conversationId'> & { conversation: UserConversation }) {
  const socket = useSocket();
  const auth = useAuth();

  // TODO: Refactor (export) replace-local-message logic
  useEffect(() => {
    const onMessage: MessageHandler = async (message) => {
      const store = getStore<ConversationCache>(`conversation/${conversation.id}`);
      if (store.state.nextCursor) {
        while (store.state.nextCursor) {
          await loadViewportMessages(auth, 'after', conversation.id, store.state.nextCursor, null);
        }
      } else {
        if (message.localId && message.user_id === auth.getUserId()) {
          store.setState((state) => {
            return {
              ...state,
              messageIds: state.messageIds?.reduce((acc, next) => {
                if (next === message.localId) {
                  acc.push(message.id!);
                } else {
                  acc.push(next);
                }

                return acc;
              }, [] as number[]),
              messagesById: Object.keys(state?.messagesById || {}).reduce(
                (acc, key) => {
                  if (Number(key) === message.localId && message.id) {
                    message.localId = message.id;
                    acc[message.id] = message;
                  } else if (state.messagesById && state.messagesById[Number(key)]) {
                    acc[Number(key)] = state.messagesById[Number(key)];
                  }
                  return acc;
                },
                {} as Record<number, Message>,
              ),
              lastMessage: message,
              lastReadId: message.id,
            };
          });
        } else {
          store.setState((state) => {
            if (state.messageIds?.includes(message.id!)) {
              // Message update
              const messagesById = { ...state.messagesById! };
              messagesById[message.id!] = { ...message, localId: message.id! };
              return {
                ...state,
                messagesById: messagesById,
              };
            } else {
              // New message
              return {
                ...state,
                messageIds: state.messageIds?.concat(message.id!),
                messagesById: {
                  ...state.messagesById,
                  [message.id!]: message,
                },
                lastMessage: message,
              };
            }
          });
        }
      }
    };

    if (conversation.is_authorized) {
      socket.on(`conversation/${conversation.id}/messages`, onMessage);
    }

    return () => {
      if (conversation.is_authorized) {
        socket.off(`conversation/${conversation.id}/messages`, onMessage);
      }
    };
  }, [conversation.id, conversation.is_authorized, socket, auth]);

  const messageIds = useSelector<ConversationCache, number[] | undefined>(
    `conversation/${conversation.id}`,
    (state) => state?.messageIds,
  );
  const messagesById = useSelector<ConversationCache, Record<number, Message> | undefined>(
    `conversation/${conversation.id}`,
    (state) => state?.messagesById,
  );
  const lastReadId = useSelector<ConversationCache, number | undefined>(
    `conversation/${conversation.id}`,
    (state) => state?.lastReadId,
  );

  return (
    <NonMemoMessageList
      conversationId={conversation.id}
      canPost={canPost}
      onFabToggle={onFabToggle}
      onNotchToggle={onNotchToggle}
      messageIds={messageIds}
      messagesById={messagesById}
      lastReadId={lastReadId || (conversation.cursor ?? undefined)}
    />
  );
});
export default MessageList;
