import { useLayoutEffect, useRef } from 'react';

const useDocumentTitle = (title: string, prepend = true, separator = ' - ') => {
  const isDocumentDefined = typeof document !== 'undefined';
  const originalTitle = useRef<string>();

  useLayoutEffect(() => {
    if (!isDocumentDefined) {
      return;
    }
    originalTitle.current = document.title;
  }, [isDocumentDefined]);

  useLayoutEffect(() => {
    if (!isDocumentDefined) {
      return;
    }

    const newTitle = prepend ? `${title}${separator}${originalTitle.current}` : title;
    if (document.title !== newTitle) {
      document.title = newTitle;
    }

    return () => {
      document.title = originalTitle.current!;
    };
  }, [title, prepend, separator, isDocumentDefined]);
};

export default useDocumentTitle;
