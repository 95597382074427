import type { ReactNode } from 'react';
import { createContext, useCallback } from 'react';

import useLocalStorage from '@/hooks/use-local-storage';
import type { Role } from '@/types/role';

export interface User {
  id: number;
  token: string;
  username: string;
  full_name: string;
  roles: Role[];
}

export interface AuthContextType {
  isAuthenticated: boolean;
  signIn: (user: User) => void;
  signOut: () => void;
  getToken: () => string | undefined;
  getUsername: () => string | undefined;
  getAuthorName: () => string | undefined;
  getUserId: () => number | undefined;
  hasRole: (role: Role) => boolean;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [currentUser, setCurrentUser] = useLocalStorage<User | undefined>('user', undefined);

  const isAuthenticated = currentUser !== undefined;

  const signIn = useCallback(
    (user: User) => {
      setCurrentUser(user);
    },
    [setCurrentUser],
  );

  const signOut = useCallback(() => {
    setCurrentUser(undefined);
  }, [setCurrentUser]);

  const getToken = useCallback(() => {
    return currentUser?.token;
  }, [currentUser]);

  const getUsername = useCallback(() => {
    return currentUser?.username;
  }, [currentUser]);

  const getAuthorName = useCallback(() => {
    return currentUser?.full_name;
  }, [currentUser]);

  const getUserId = useCallback(() => {
    return currentUser?.id;
  }, [currentUser]);

  const hasRole = useCallback(
    (role: Role) => {
      return currentUser?.roles?.includes(role) || false;
    },
    [currentUser],
  );

  const value = {
    isAuthenticated,
    signIn,
    signOut,
    getToken,
    getUsername,
    getAuthorName,
    getUserId,
    hasRole,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
