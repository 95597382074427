import { PhoneOutlined } from '@ant-design/icons';
import { type FC, useMemo, useRef } from 'react';

import { type ObserveFn, useOnIntersect } from '@/hooks/use-intersection-observer.ts';
import type { Message } from '@/types/message.ts';
import buildClassName from '@/utils/build-class-name.ts';
import { formatTime } from '@/utils/date-format.ts';

import styles from './ServiceMessage.module.scss';

function getMessageHtmlId(messageId: number) {
  return `message${messageId.toString().replace('.', '-')}`;
}

interface OwnProps {
  message: Message;
  observeIntersectionForBottom: ObserveFn;
}

const ServiceMessage: FC<OwnProps> = ({ message, observeIntersectionForBottom }) => {
  const messageDate = useMemo(() => new Date(message.created_at), [message.created_at]);
  const bottomMarkerRef = useRef<HTMLDivElement>(null);
  useOnIntersect(bottomMarkerRef, observeIntersectionForBottom);
  const messageId = (message.id || message.localId) as number;

  const containerClassName = buildClassName('message-list-item', styles.actionMessage);

  return (
    <div id={getMessageHtmlId(messageId)} className={containerClassName} data-message-id={messageId}>
      <div ref={bottomMarkerRef} className="bottom-marker" data-message-id={messageId} />
      {message.author_name && (
        <div className={styles.messageTitle} dir="ltr">
          Service message triggered by {message.author_name} at {formatTime(messageDate)}
          {message.author_phone && (
            <a
              className={styles.messageTitlePhone}
              href={`tel:${message.author_phone}`}
              title={`Call ${message.author_name}`}
            >
              <PhoneOutlined />
            </a>
          )}
        </div>
      )}
      <div className={styles.messageContent}>{message.body}</div>
    </div>
  );
};

export default ServiceMessage;
