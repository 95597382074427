import type { AuthContextType } from '@/context/auth.context.tsx';
import { apiRequest, isResponse } from '@/services/fetcher.ts';
import type { TelegramUser } from '@/types/telegram-user.ts';

async function fetchConversationTelegramUsers(auth: AuthContextType, conversationId: number, options?: RequestInit) {
  const url = `${import.meta.env.VITE_API_URL}/conversation/${conversationId}/telegram-users`;
  const response = await apiRequest<TelegramUser[]>(url, auth, options);

  if (isResponse(response)) {
    return {
      status: response.status,
      statusText: response.statusText,
    } as Response;
  }

  if (response instanceof Error) {
    throw response;
  }

  return response;
}

export default fetchConversationTelegramUsers;
