import { json, type V7_FormMethod } from 'react-router-dom';

import type { AuthContextType } from '@/context/auth.context.tsx';
import { apiRequest, isResponse } from '@/services/fetcher.ts';
import { ConflictError } from '@/services/types/conflict-error.ts';
import { ValidationError } from '@/services/types/validation-error.ts';

export const actionRequest = async <T>(auth: AuthContextType, request: Request, url: string, data?: unknown) => {
  const baseUrl = `${import.meta.env.VITE_API_URL}`;
  const method = request.method.toUpperCase() as V7_FormMethod;
  const options: RequestInit = {
    method: method,
    signal: request.signal,
    headers: request.headers,
  };

  // let response: Error | Response | T;

  if (method !== 'DELETE' && data !== undefined) {
    // We store action in intent, which needs to be removed before sending the request to the backend
    if (data && typeof data === 'object' && 'intent' in data) {
      delete data.intent;
    }
    options.body = JSON.stringify(data);
  }

  const response = await apiRequest<T>(`${baseUrl}/${url}`, auth, options);

  if (response instanceof ValidationError) {
    return json({
      ok: false,
      statusText: response.message,
      details: response.details,
    });
  }

  if (response instanceof ConflictError) {
    return json({
      ok: false,
      statusText: response.message,
    });
  }

  if (response instanceof Error) {
    // Re-throw any errors we don't handle specifically
    throw response;
  }

  if (isResponse(response)) {
    return json({
      ok: response.ok,
      status: response.status,
      statusText: response.statusText,
    });
  }

  return response;
};
