import { Attachment } from '@/types/attachment.ts';

const FILE_EXTENSION_REGEX = /\.[^/.]+$/;
export const SUPPORTED_IMAGE_CONTENT_TYPES = new Set(['image/png', 'image/jpeg', 'image/gif']);
export const SUPPORTED_VIDEO_CONTENT_TYPES = new Set(['video/mp4', 'video/quicktime']);
export const SUPPORTED_AUDIO_CONTENT_TYPES = new Set([
  'audio/mp3',
  'audio/ogg',
  'audio/wav',
  'audio/mpeg',
  'audio/flac',
  'audio/aac',
  'audio/m4a',
  'audio/mp4',
  'audio/x-m4a',
]);

export default function buildAttachment(filename: string, blob: Blob): Attachment {
  const blobUrl = URL.createObjectURL(blob);
  const { size } = blob;
  let { type: mimeType } = blob;

  // TODO: can build thumbnails here (by building preview blobs) to show before sending

  if (mimeType === '' && blob instanceof File) {
    const extension = blob.name.match(FILE_EXTENSION_REGEX)?.[0];
    // Is it safe to assume that QuickTime video is with mp4 codec, or extension check is enough?
    if (extension === '.mov') {
      mimeType = 'video/quicktime';
    }
  }

  // Fix uncommon jpeg extensions
  if (mimeType === 'image/jpeg') {
    filename = filename.replace(FILE_EXTENSION_REGEX, '.jpg');
  }

  // TODO: revoke blob urls when attachments are synced back to client
  return {
    blobUrl,
    filename,
    mimeType,
    size,
    uniqueId: `${Date.now()}-${Math.random()}`,
    file: new File([blob], filename, { type: mimeType }),
  };
}

export function getAttachmentType(attachment: Attachment): 'photo' | 'video' | 'audio' | 'document' {
  if (SUPPORTED_IMAGE_CONTENT_TYPES.has(attachment.mimeType)) {
    return 'photo';
  }

  if (SUPPORTED_VIDEO_CONTENT_TYPES.has(attachment.mimeType)) {
    return 'video';
  }

  if (SUPPORTED_AUDIO_CONTENT_TYPES.has(attachment.mimeType)) {
    return 'audio';
  }

  return 'document';
}

export function getImageDimensions(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => resolve({ width: image.width, height: image.height });
    image.src = url;
  });
}

export function getVideoDimensions(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.addEventListener('loadedmetadata', () => resolve({ width: video.videoWidth, height: video.videoHeight }));
    video.src = url;
  });
}

const ONE_GIGABYTE = 1024 * 1024 * 1024;
const ONE_MEGABYTE = 1024 * 1024;

export function getFileSizeString(bytes: number) {
  if (bytes > ONE_GIGABYTE / 2) {
    return `${(bytes / ONE_GIGABYTE).toFixed(1).replace(/\.0$/, '')} GB`;
  }
  if (bytes > ONE_MEGABYTE / 2) {
    return `${(bytes / ONE_MEGABYTE).toFixed(1).replace(/\.0$/, '')} MB`;
  }
  return `${(bytes / 1024).toFixed(1).replace(/\.0$/, '')} KB`;
}
