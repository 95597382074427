import File from '@pages/ChatFeed/components/File';
import { type FC, useCallback } from 'react';

import useAuth from '@/hooks/use-auth.ts';
import useMedia, { downloadToLocalObject } from '@/hooks/use-media.ts';
import type { ApiDocument } from '@/types/messages.ts';

import styles from './Document.module.scss';

interface OwnProps {
  id: number;
  document: ApiDocument;
}

const Document: FC<OwnProps> = ({ id, document: apiDocument }) => {
  const auth = useAuth();
  const thumbnailUrl = useMedia(id, apiDocument.thumbnail !== undefined, apiDocument.thumbnail?.file_id);

  const onDownload = useCallback(async () => {
    const url = `${import.meta.env.VITE_API_URL}/message/${id}/media`;
    const localBlobUrl = await downloadToLocalObject(auth, url);
    const anchor = document.createElement('a');
    anchor.href = localBlobUrl;
    if (apiDocument.file_name) {
      anchor.download = apiDocument.file_name;
    }
    anchor.dispatchEvent(new MouseEvent('click'));
    setTimeout(() => {
      URL.revokeObjectURL(localBlobUrl);
      anchor.remove();
    }, 100);
  }, [id, apiDocument, auth]);

  return (
    <div className={styles.document}>
      <File file={apiDocument} onDownloadClick={onDownload} thumbnailUrl={thumbnailUrl} />
    </div>
  );
};

export default Document;
