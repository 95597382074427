import { Player } from '@lottiefiles/react-lottie-player';
import type { FC } from 'react';

interface OwnProps {
  url: string;
}

const AnimatedSticker: FC<OwnProps> = ({ url }) => {
  // TODO: do not play animations on out of view messages
  return <Player src={url} autoplay loop />;
};

export default AnimatedSticker;
