import type { FC } from 'react';
import { useMemo } from 'react';
import { useRouteError } from 'react-router-dom';

import styles from './ErrorBoundary.module.scss';

const ErrorBoundary: FC = () => {
  const error = useRouteError();

  const errorMessage = useMemo(() => {
    if (typeof error === 'string') {
      return error;
    }

    if (error instanceof Error && 'message' in error) {
      return error.message;
    }
  }, [error]);

  return (
    <div className={styles.errorWrapper}>
      <div className={styles.errorDialog}>
        <h2 className={styles.errorTitle}>Something went wrong!</h2>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        <h4>
          Please either refresh the page to try again or use the browser back button to return to the previous page.
        </h4>
      </div>
    </div>
  );
};

export default ErrorBoundary;
