export interface ValidationErrorResponse {
  ok: boolean;
  statusText: string;
  details?: Record<string, string[]>;
}
export const isValidationErrorResponse = <T extends object>(
  response: ValidationErrorResponse | Response | T,
): response is ValidationErrorResponse => {
  return 'ok' in response && !response.ok && 'statusText' in response && response.statusText === 'Validation failed';
};
