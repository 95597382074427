import { LoaderFunction } from 'react-router-dom';

import { AuthContextType } from '@/context/auth.context';
import { apiRequest, isResponse } from '@/services/fetcher';
import { TelegramMenuItem } from '@/types/telegram-menu-item.ts';

export const loader = (auth: AuthContextType) =>
  (async ({ request }) => {
    const response = await apiRequest<TelegramMenuItem[]>(
      `${import.meta.env.VITE_API_URL}/manage/telegram-menu`,
      auth,
      {
        signal: request.signal,
      },
    );

    if (isResponse(response)) {
      return {
        status: response.status,
        statusText: response.statusText,
      } as Response;
    }

    if (response instanceof Error) {
      throw response;
    }

    return response;
  }) satisfies LoaderFunction;
