import { type ActionFunction, json } from 'react-router-dom';

import type { AuthContextType } from '@/context/auth.context';
import { apiRequest, isResponse } from '@/services/fetcher';
import { ConflictError } from '@/services/types/conflict-error.ts';
import type { User } from '@/types/user';

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const formData = await request.formData();
    const intent = formData.get('intent');
    formData.delete('intent');

    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1000);

    if (intent === 'add') {
      const response = await apiRequest<User>(`${import.meta.env.VITE_API_URL}/manage/user`, auth, {
        method: request.method,
        signal: request.signal,
        body: formData,
      });

      if (response instanceof ConflictError) {
        return json({ ok: false, statusText: response.message });
      }

      if (response instanceof Error) {
        throw response;
      }

      if (isResponse(response)) {
        return {
          status: response.status,
          statusText: response.statusText,
        } as Response;
      }

      return response;
    } else if (intent === 'update') {
      const userId = formData.get('id');
      const response = await apiRequest<User>(`${import.meta.env.VITE_API_URL}/manage/user/${userId}`, auth, {
        method: request.method,
        signal: request.signal,
        body: formData,
      });

      if (response instanceof ConflictError) {
        return json({ ok: false, statusText: response.message });
      }

      if (response instanceof Error) {
        throw response;
      }

      return response;
    } else {
      const userId = formData.get('id');
      const response = await apiRequest<Response>(`${import.meta.env.VITE_API_URL}/manage/user/${userId}`, auth, {
        method: request.method,
        signal: request.signal,
      });

      if (response instanceof Error) {
        throw response;
      }

      return json({ ok: true, statusText: 'No Content' });
    }
  }) satisfies ActionFunction;
