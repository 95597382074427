import { AuthContextType } from '@/context/auth.context';
import { apiRequest, isResponse } from '@/services/fetcher';
import { ApiMessage } from '@/types/message';
import { CursorPaginatedResponse } from '@/utils/api/api-response';

export type LoadDirection = 'before' | 'after' | 'both';

async function fetchCursorMessages(
  auth: AuthContextType,
  conversationId: number,
  cursor: number | null = null,
  direction: LoadDirection = 'before',
  limit: number | null = 60,
): Promise<CursorPaginatedResponse<ApiMessage[]>> {
  let url = `${import.meta.env.VITE_API_URL}/conversation/${conversationId}/message`;
  if (limit) {
    url = `${url}?limit=${limit}`;
  }
  if (cursor) {
    url = `${url}${limit ? '&' : '?'}cursor=${cursor}&direction=${direction}`;
  }
  const response = await apiRequest<CursorPaginatedResponse<ApiMessage[]>>(url, auth);

  if (isResponse(response)) {
    throw new Error('Unexpected response');
  }

  if (response instanceof Error) {
    throw response;
  }

  return {
    ...response,
    data: response.data.map((message) => ({
      ...message,
      localId: message.id,
    })),
  };
}

export default fetchCursorMessages;
