import type { AuthContextType } from '@/context/auth.context';
import { apiRequest, isResponse } from '@/services/fetcher';
import type { Message } from '@/types/message.ts';
import type { TelegramGroup } from '@/types/telegram-group.ts';
import type { OffsetPaginatedResponse } from '@/utils/api/api-response';

export type UserMessage = Pick<Message, 'id' | 'body' | 'type' | 'created_at'> & Pick<TelegramGroup, 'name'>;

async function fetchPaginatedUserMessages(auth: AuthContextType, page = 1, pageSize = 10, options?: RequestInit) {
  const response = await apiRequest<OffsetPaginatedResponse<UserMessage>>(
    `${import.meta.env.VITE_API_URL}/organization/my-messages?page=${page}&limit=${pageSize}`,
    auth,
    options,
  );

  if (isResponse(response)) {
    return {
      status: response.status,
      statusText: response.statusText,
    } as Response;
  }

  if (response instanceof Error) {
    throw response;
  }

  return response;
}

export default fetchPaginatedUserMessages;
