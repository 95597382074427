import { ApiMessage, Message } from '@/types/message.ts';

/** TypeScript Omit (Exclude to be specific) does not work for objects with an "any" indexed type, and breaks discriminated unions @public */
export type EnhancedOmit<TRecordOrUnion, KeyUnion> = string extends keyof TRecordOrUnion
  ? TRecordOrUnion // TRecordOrUnion has indexed type e.g. { _id: string; [k: string]: any; } or it is "any"
  : TRecordOrUnion extends unknown
    ? Pick<TRecordOrUnion, Exclude<keyof TRecordOrUnion, KeyUnion>> // discriminated unions
    : never;

export function isApiMessage(message: Message): message is ApiMessage {
  return message.id !== undefined;
}

export interface ApiMediaMessage<T extends Record<string, unknown> | Record<string, unknown>[]> extends ApiMessage {
  extra_attributes: T;
}

export interface ApiFile extends Record<string, unknown> {
  file_unique_id: string;
  file_id: string;
  file_size?: number;
  file_name?: string;
  mime_type?: string;
}

export interface ApiSticker extends EnhancedOmit<ApiFile, 'file_name' | 'mime_type'> {
  is_animated: boolean;
  is_video: boolean;
  set_name?: string;
  thumbnail?: ApiPhoto;
  emoji?: string;
  width: number;
  height: number;
}

export interface ApiPhoto extends EnhancedOmit<ApiFile, 'file_name' | 'mime_type'> {
  width: number;
  height: number;
}

export interface ApiVideo extends ApiFile {
  duration: number;
  thumbnail?: ApiPhoto;
  width: number;
  height: number;
}

export interface ApiVideoNote extends EnhancedOmit<ApiFile, 'file_name' | 'mime_type'> {
  duration: number;
  length: number;
  thumbnail?: ApiPhoto;
}

export interface ApiVoice extends EnhancedOmit<ApiFile, 'file_name'> {
  duration: number;
}

export interface ApiAudio extends ApiFile {
  duration: number;
  performer?: string;
  title?: string;
  thumbnail?: ApiPhoto;
}

export interface ApiDocument extends ApiFile {
  thumbnail?: ApiPhoto;
}

export function isStickerMessage(message: Message): message is ApiMediaMessage<ApiSticker> {
  return isApiMessage(message) && message.type === 'sticker';
}

export function isPhotoMessage(message: Message): message is ApiMediaMessage<ApiPhoto[]> {
  return isApiMessage(message) && message.type === 'photo';
}

export function isVideoLikeMessage(message: Message): message is ApiMediaMessage<ApiVideoNote | ApiVideo> {
  return isApiMessage(message) && (message.type === 'video_note' || message.type === 'video');
}

export function isPlayableVideoMessage(message: ApiMediaMessage<ApiVideo | ApiVideoNote>) {
  const videoElement = document.createElement('video');
  const canPlay = videoElement.canPlayType(
    'mime_type' in message.extra_attributes ? (message.extra_attributes.mime_type as string) : 'video/mp4',
  );
  const isPlayableVideo = canPlay === 'probably' || canPlay === 'maybe';
  videoElement.remove();
  return isPlayableVideo;
}

export function isAudioLikeMessage(message: Message): message is ApiMediaMessage<ApiVoice | ApiAudio> {
  return isApiMessage(message) && (message.type === 'voice' || message.type === 'audio');
}

export function isDocumentMessage(message: Message): message is ApiMediaMessage<ApiDocument> {
  return isApiMessage(message) && message.type === 'document';
}

export function isFileMessage(message: Message): message is ApiMediaMessage<ApiFile> {
  return isApiMessage(message) && (message.type === 'video' || message.type === 'audio' || message.type === 'document');
}

export function fileExceedsTGAPILimit(file: ApiFile) {
  const TG_FILE_SIZE_LIMIT = 20 * 1024 * 1024;
  return file.file_size !== undefined && file.file_size > TG_FILE_SIZE_LIMIT;
}
