import { Button, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { type FC, useCallback, useEffect } from 'react';
import { useFetcher } from 'react-router-dom';

import type { TelegramMenuItemTranslation } from '@/types/telegram-menu-item-translation.ts';

const isErrorResponse = (response: Response | TelegramMenuItemTranslation): response is Response => {
  return 'ok' in response && !response.ok;
};

interface Values {
  label: string;
  answer: string;
  language_code: string;
}

interface OwnProps {
  open: boolean;
  onCreate: (translation: TelegramMenuItemTranslation) => void;
  onCancel: () => void;
  item_id: number;
  languages: Record<string, string>;
}

const MenuItemAddTranslationForm: FC<OwnProps> = ({ open, onCreate, onCancel, item_id, languages }) => {
  const { submit, state, data: response } = useFetcher<Response | TelegramMenuItemTranslation>();
  const [form] = useForm<Values>();

  useEffect(() => {
    if (response === undefined) {
      return;
    }

    if (isErrorResponse(response)) {
      form.setFields([
        {
          name: 'label',
          errors: [response.statusText],
        },
      ]);
      return;
    }

    form.resetFields();
    onCreate(response);
  }, [form, onCreate, response]);

  const onSubmit = useCallback(async () => {
    const values = await form.validateFields();
    submit(
      {
        intent: 'add-menu-item-translation',
        ...Object.fromEntries(Object.entries(values).filter(([, value]) => value !== undefined)),
        item_id: `${item_id}`,
      },
      {
        method: 'post',
      },
    );
  }, [item_id, form, submit]);

  const onTriggerCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <Modal
      open={open}
      title="Add Menu Item Translation"
      onCancel={onTriggerCancel}
      footer={[
        <Button key="cancel" onClick={onTriggerCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={state === 'submitting'} onClick={form.submit}>
          Add
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        disabled={state === 'submitting'}
        initialValues={{ language_code: Object.keys(languages)[0] }}
      >
        <Form.Item
          name="label"
          label="Label"
          rules={[
            {
              required: true,
              message: 'Please input the label!',
            },
            {
              max: 64,
              message: 'Label can not exceed 64 characters.',
            },
          ]}
        >
          <Input count={{ show: true, max: 64 }} />
        </Form.Item>
        <Form.Item name="answer" label="Telegram message">
          <Input />
        </Form.Item>
        <Form.Item
          name="language_code"
          label="Language"
          rules={[
            {
              required: true,
              message: 'Please select the language!',
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={Object.entries(languages).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MenuItemAddTranslationForm;
