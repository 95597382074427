import { LoadingOutlined } from '@ant-design/icons';
import AnimatedSticker from '@pages/ChatFeed/RightColumn/MessageList/MessageListContent/Message/Sticker/AnimatedSticker';
import { Spin } from 'antd';
import { type FC, useEffect, useRef, useState } from 'react';

import { getStickerDimensions } from '@/helpers/mediaDimensions.ts';
import useMedia from '@/hooks/use-media.ts';
import type { ApiSticker } from '@/types/messages.ts';

import styles from './Sticker.module.scss';

interface OwnProps {
  id: number;
  sticker: ApiSticker;
}

const Sticker: FC<OwnProps> = ({ id, sticker }) => {
  const [shouldSpinnerRender, setShouldSpinnerRender] = useState(true);
  const { width, height } = getStickerDimensions(sticker);
  const videoRef = useRef<HTMLVideoElement>(null);
  // TODO: we can probably load sticker (as well as other media types) from backend the first time the message is
  // in the view and cache it to make it lazy loadable
  const mediaUrl = useMedia(id);

  useEffect(() => {
    setShouldSpinnerRender(mediaUrl === undefined);
  }, [mediaUrl]);

  // Blob url doesn't work directly on video element, bug in Chromium, so we need to set it programmatically
  // https://issues.chromium.org/issues/40672362
  useEffect(() => {
    if (mediaUrl && videoRef.current) {
      videoRef.current.src = mediaUrl;
      videoRef.current.loop = true;
      videoRef.current.muted = true;
      // noinspection JSIgnoredPromiseFromCall
      videoRef.current.play();
    }
  }, [mediaUrl]);

  // TODO: We can add thumbnail in front, which will probably load a way faster than original files, and swap them when ready
  return (
    <Spin indicator={<LoadingOutlined spin />} size="large" spinning={shouldSpinnerRender}>
      <div className="media-inner" style={{ width: `${width}px`, height: `${height}px` }}>
        {sticker.is_animated ? (
          <AnimatedSticker url={mediaUrl!} />
        ) : sticker.is_video ? (
          <video ref={videoRef} width={width} height={height} muted autoPlay />
        ) : (
          <img className={styles.media} src={mediaUrl} alt={sticker.emoji} draggable={false} />
        )}
      </div>
    </Spin>
  );
};

export default Sticker;
